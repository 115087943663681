// -----------------------------------------
// 外部連携のサイトを使用する際にこのファイルを利用
// -----------------------------------------


/////////////////////////
// (HOME/物件管理/周辺情報)
/////////////////////////

// 固定資産税路線価
export const fixedAssetTaxStreetPriceLink = (lng:string | number,lat:string | number, other = "") => {
  return `https://www.chikamap.jp/chikamap/Map?mid=224&mpx=${lng}&mpy=${lat}&bsw=1333&bsh=763`
}
// 相続税路線価
export const inheritanceTaxRoadValueLink = (lng:string | number,lat:string | number, other = "") => {
  return `https://www.chikamap.jp/chikamap/Map?mid=324&mpx=${lng}&mpy=${lat}&bsw=1333&bsh=763`
}
// 地価公示・地価調査
export const chikaKojiChosaLink = (lng:string | number,lat:string | number, other = "") => {
  return `https://www.chikamap.jp/chikamap/Map?mid=124&mpx=${lng}&mpy=${lat}&bsw=1333&bsh=763`
}
// 地番サポートマップ
export const parcelSupportMapLink = (lat:string | number,lng:string | number, other = "") => {
  // サイトの仕様上latが先でlngが後なので注意
  return `https://sumaken.j-shield.co.jp/supportmap/#14,${lat},${lng}`
};
// REIT.map
export const jReitMapLink = (other = "") => {
  return `https://reit-map.net/jreit/map`
};
// export const jreitMapLink =  'https://reit-map.net/jreit/map';
// 埋蔵文化財包蔵地
export const buriedCulturalPropertyLinks = (prefecture:string = "", city:string = "", other:any = "") => {
  const links = [
    { place_name: "東京都", link: `https://tokyo-iseki.metro.tokyo.lg.jp/` },
    { place_name: "神奈川県", link: `https://www2.wagmap.jp/pref-kanagawa/Agreement?IsPost=False&MapId=50&RequestPage=%2fpref-kanagawa%2fPositionSelect%3fmid%3d50%26nm%3d%25E6%2596%2587%25E5%258C%2596%25E8%25B2%25A1%25E9%2598%25B2%25E7%2581%25BD%25E3%2583%259E%25E3%2583%2583%25E3%2583%2597%26ctnm%3d%25E6%2596%2587%25E5%258C%2596` },
    { place_name: "埼玉県", link: `https://www.pref.saitama.lg.jp/isekimap/` },
    { place_name: "千葉県", link: `https://map.pref.chiba.lg.jp/pref-chiba/Agreement?IsPost=False&MapId=30&RequestPage=%2fpref-chiba%2fPositionSelect%3fmid%3d30` },
    { place_name: "愛知県", link: `https://profile.maps.pref.aichi.jp/lib/map.php?mid=20055` },
    { place_name: "大阪府", link: `https://www.pref.osaka.lg.jp/o130030/jigyokanri/cals/tizu.html` },
    { place_name: "福岡市", link: `https://webmap.city.fukuoka.lg.jp/fukuoka/PositionSelect?mid=68&nm=%E5%9F%8B%E8%94%B5%E6%96%87%E5%8C%96%E8%B2%A1%EF%BC%88%E9%81%BA%E8%B7%A1%EF%BC%89%E5%88%86%E5%B8%83%E3%83%9E%E3%83%83%E3%83%97&ctnm=%E5%9F%8B%E8%94%B5%E6%96%87%E5%8C%96%E8%B2%A1%EF%BC%88%E9%81%BA%E8%B7%A1%EF%BC%89` },
  ];

  if (prefecture === "福岡県" && city.includes("福岡市")) {
    //city が「福岡市東区」や「福岡市中央区」のような場合でも、place_name が「福岡市」と一致するリンクを返す
    const result = links.find(link => link.place_name === "福岡市");
    return result ? result.link : null;
  }

  if (prefecture !== "") {
    const result = links.find(link => link.place_name === prefecture);
    return result ? result.link : null;
  }

  return false;
};