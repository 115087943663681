import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import ClearIcon from '@material-ui/icons/Clear'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { AnchorButton } from '@/components/Button'
import { GoogleMap } from '@react-google-maps/api'
import LocationCitySharpIcon from '@mui/icons-material/LocationCitySharp'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import { useFullScreen } from '../viewFullscreen'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import * as THREE from 'three'
import { ThreeJSOverlayView } from '@googlemaps/three'
import '@/components/Page/VolumeCheckRequests/BuildingMap/style.css'

type Props = {
  gltfJson: React.RefObject<string>
  propertyShape: string
}

export const BuildingMapModal: React.FC<Props> = ({ gltfJson, propertyShape }) => {
  const {
    elementRef: buildMapRef,
    triggerFullScreen: triggerMapFullScreen,
    exitFullScreen: exitMapFullScreen,
    isFullScreen: isMapFullScreen,
  } = useFullScreen()

  const containerStyle = React.useMemo(() => {
    return {
      width: '100%',
      height: isMapFullScreen ? '100%' : '75vh',
    }
  }, [isMapFullScreen])

  const mapOptions = React.useMemo(() => {
    const bounds = new google.maps.LatLngBounds()
    const latlngs = JSON.parse(propertyShape).map((item) => {
      return new google.maps.LatLng(item.lat, item.lng)
    })
    latlngs.forEach((item) => {
      bounds.extend(item)
    })

    return {
      center: bounds.getCenter(),
      zoom: 18,
      minZoom: 18,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      draggable: false,
      tilt: 60,
      heading: 0,
      mapId: '4175b2934720f604',
      mapTypeId: 'roadmap',
    }
  }, [propertyShape])

  const mapRef = React.useRef<google.maps.Map>(null)

  const onLoad = (map: google.maps.Map) => {
    mapRef.current = map
    showThreeJSOverlayView(map)
    showAreaPolygon(map)

    const mapTypeButton = document.createElement('button')
    mapTypeButton.textContent = '航空写真'
    mapTypeButton.classList.add('custom-map-control-button')
    mapTypeButton.setAttribute('type', 'button')
    mapTypeButton.style.fontSize = '16px'

    mapTypeButton.addEventListener('click', () => {
      // 現在のマップタイプを確認
      const currentType = map.getMapTypeId()
      const isRoadMap = currentType === 'roadmap' || currentType === 'styled_map'
      const currentTilt = map.getTilt()

      if (isRoadMap) {
        map.setMapTypeId(google.maps.MapTypeId.SATELLITE)
        map.setTilt(currentTilt)
        mapTypeButton.textContent = '地図'
      } else {
        map.setMapTypeId('roadmap')
        map.setTilt(currentTilt)
        mapTypeButton.textContent = '航空写真'
      }
    })

    map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(mapTypeButton)
  }

  //敷地の表示
  const showAreaPolygon = (map: google.maps.Map) => {
    createAreaPolygon.setMap(map)
  }

  //敷地の描画
  const createAreaPolygon = React.useMemo(() => {
    const polygon = new google.maps.Polygon({
      paths: JSON.parse(propertyShape).map((item) => {
        return new google.maps.LatLng(item.lat, item.lng)
      }),
      draggable: false,
      fillColor: 'transparent',
      fillOpacity: 0.5,
      strokeWeight: 1,
      strokeOpacity: 0.1,
      clickable: false,
      editable: false,
      zIndex: 1,
    })

    return polygon
  }, [propertyShape])

  //ThreeJSOverlayViewの表示
  const showThreeJSOverlayView = (map: google.maps.Map) => {
    const latLngAltitudeLiteral = {
      lat: mapOptions.center.lat(),
      lng: mapOptions.center.lng(),
      altitude: 0,
    }
    const scene = createThreeJSOverlayView
    new ThreeJSOverlayView({
      map,
      scene,
      anchor: latLngAltitudeLiteral,
      THREE,
    })
  }

  //ThreeJSOverlayViewの作成
  const createThreeJSOverlayView = React.useMemo(() => {
    //Three.js シーンを作成します。
    const scene = new THREE.Scene()
    //AmbientLight: シーン内のすべてのオブジェクトをあらゆる角度から均等に照らす拡散光源を提供します。
    //これにより、シーンの基本的な明るさが提供され、すべてのオブジェクト テクスチャが確実に表現されます。
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.1)
    scene.add(ambientLight)

    //モデルローダーインスタンスを作成します。
    const loader = new GLTFLoader()
    //JSON データを使用して 3D モデルを読み込みます。
    if (gltfJson.current !== '') {
      loader.parse(gltfJson.current, '', (gltf) => {
        //サイズスケーリング
        //gltf.scene.scale.set(0.001,0.001,0.001);
        //ラジアンから角度への公式: 度 * Math.PI/180
        gltf.scene.rotation.x = (-90 * Math.PI) / 180
        //gltf.scene.rotation.z = 25 * Math.PI/180;

        scene.add(gltf.scene)

        // シーン内のすべてのオブジェクトを反復処理します。
        scene.traverse((object) => {
          if (object.name.includes('delFromMap')) {
            object.clear()
            scene.remove(object)
          }
        })
      })
    }
    return scene
  }, [gltfJson.current])

  const upText = React.useRef('up')
  const downText = React.useRef('down')
  const leftText = React.useRef('left')
  const rightText = React.useRef('right')

  //ボタンクリックイベント
  const rotateClick = (type: string) => {
    let heading = mapRef.current.getHeading()
    let tilt = mapRef.current.getTilt()

    switch (type) {
      case upText.current:
        if (tilt > 0) {
          tilt = tilt - 10 > 0 ? tilt - 10 : 0
        }
        break
      case downText.current:
        if (tilt < 90) {
          tilt = tilt + 10 < 90 ? tilt + 10 : 90
        }
        break
      case leftText.current:
        heading = (heading + 10) % 360
        break
      case rightText.current:
        heading = (heading - 10) % 360
        break
    }

    mapRef.current.setTilt(tilt)
    mapRef.current.setHeading(heading)
  }

  const [isOpen, setIsOpen] = React.useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <AnchorButton className="w-8 h-8" onClick={openModal}>
        <LocationCitySharpIcon fontSize="small" />
      </AnchorButton>
      <Transition.Root appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-4/5 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="relative" ref={buildMapRef}>
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      options={mapOptions}
                      onLoad={onLoad}
                    />
                    <AnchorButton
                      className="absolute right-2 top-2 w-8 h-8 z-10"
                      onClick={closeModal}
                    >
                      <ClearIcon fontSize="small" />
                    </AnchorButton>
                    <AnchorButton
                      className="absolute right-2 top-12 w-8 h-8 z-10"
                      onClick={isMapFullScreen ? exitMapFullScreen : triggerMapFullScreen}
                    >
                      {isMapFullScreen ? (
                        <FullscreenExitIcon fontSize="small" />
                      ) : (
                        <FullscreenIcon fontSize="small" />
                      )}
                    </AnchorButton>
                    <div className="rotate-container">
                      <div className="rotate-row">
                        <div
                          className="rotate-up rotate"
                          onClick={() => {
                            rotateClick(upText.current)
                          }}
                        >
                          <ArrowDropUpIcon />
                        </div>
                        <div
                          className="rotate-right rotate"
                          onClick={() => {
                            rotateClick(rightText.current)
                          }}
                        >
                          <ArrowRightIcon />
                        </div>
                      </div>
                      <div className="rotate-row">
                        <div
                          className="rotate-left rotate"
                          onClick={() => {
                            rotateClick(leftText.current)
                          }}
                        >
                          <ArrowLeftIcon />
                        </div>
                        <div
                          className="rotate-down rotate"
                          onClick={() => {
                            rotateClick(downText.current)
                          }}
                        >
                          <ArrowDropDownIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default BuildingMapModal
