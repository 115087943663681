import { Breadcrumb } from '@/components/Breadcrumb'
import { AnchorButton, Button } from '@/components/Button'
import { OldGoogleMapViewer as GoogleMapViewer } from '@/components/GoogleMapViewer'
import Base from '@/components/Layout/Base'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import { Table } from '@/components/Table'
import { User } from '@/types/user'
import { VolumeCheckDetail } from '@/types/volumeCheckDetail'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useJsApiLoader } from '@react-google-maps/api'
import * as React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import ReactSelect, { MultiValue } from 'react-select'
import BuildingMapModal from './BuildingMap/BuildingMapModal'
import { Drawing2dView } from './DrawingViewer/2d/Drawing2dView'
import { Drawing3dView } from './DrawingViewer/3d/Drawing3dView'
import { downloadText } from './helpers/DownloadHelper'
import { svg2dxf } from './helpers/Svg2Dxf'
import { useFullScreen } from './viewFullscreen'
import {
  unit_int_pct,
  unit_m2,
  unit_meter,
  unit_num,
  unit_pct,
  unit_tsubo,
} from './volumeUnitFormat'

type Props = {
  current_user: User
  volume_check_detail: VolumeCheckDetail
  google_maps_api_key: string
  polygons_api_base_url: string
  env_name: string
  jigyoplan: []
  jigyoplan_floor_infos: []
}

type Option = {
  label: string
  value: number
}

const VolumeCheckRequestDetailPage: React.FC<Props> = ({
  current_user,
  volume_check_detail,
  google_maps_api_key,
  polygons_api_base_url,
  env_name,
  jigyoplan,
  jigyoplan_floor_infos,
}) => {
  const title = 'Volume 詳細'
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: ['geometry', 'drawing', 'places'],
  })

  if (env_name === 'production') {
    volume_check_detail.drawing_file.surroundings = null;
  }

  const [isBirdCage, setIsBirdCage] = React.useState<boolean>(false)
  const [svg, setSvg] = React.useState<SVGSVGElement | null>(null)
  const [selectedFloor, setSelectedFloor] = React.useState<Option | undefined>(undefined)
  const [selectedSkyFactorPoints, setSelectedSkyFactorPoints] = React.useState<MultiValue<Option>>(
    []
  )
  const [selectedSkyFactorPoints2d, setSelectedSkyFactorPoints2d] = React.useState<MultiValue<Option>>(
    []
  )
  const {
    elementRef: mapRef,
    triggerFullScreen: triggerMapFullScreen,
    exitFullScreen: exitMapFullScreen,
    isFullScreen: isMapFullScreen,
  } = useFullScreen()
  const {
    elementRef: dxfRef,
    triggerFullScreen: triggerDxfFullScreen,
    exitFullScreen: exitDxfFullScreen,
    isFullScreen: isDxfFullScreen,
  } = useFullScreen()
  const {
    elementRef: drawingRef,
    triggerFullScreen: triggerDrawingFullScreen,
    exitFullScreen: exitDrawingFullScreen,
    isFullScreen: isDrawingFullScreen,
  } = useFullScreen()

  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  const computeDistance = (from, to) => {
    let distance = google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(from.lat, from.lng),
      new google.maps.LatLng(to.lat, to.lng)
    )
    return distance.toFixed(2)
  }

  const { floors, skyFactor } = React.useMemo(() => {
    const { drawing_file } = volume_check_detail
    return {
      floors: drawing_file?.floors ?? [],
      skyFactor: {
        points: drawing_file?.sky_factor?.factors ?? [],
      },
    }
  }, [volume_check_detail])

  const skyFactorPointOptions = React.useMemo(() => {
    const keys = Object.keys(skyFactor.points)
    return keys.map((idx) => {
      return {
        value: Number(idx),
        label: `${idx}`,
      }
    })
  }, [skyFactor])

  React.useEffect(() => {
    setSelectedSkyFactorPoints(skyFactorPointOptions)
  }, [skyFactorPointOptions])

  React.useEffect(() => {
    setSelectedSkyFactorPoints2d(skyFactorPointOptions)
  }, [skyFactorPointOptions])

  const filteredSkyFactorIndices = React.useMemo(() => {
    return selectedSkyFactorPoints.map(({ value }) => {
      return value
    })
  }, [selectedSkyFactorPoints])

  const filteredSkyFactorIndices2d = React.useMemo(() => {
    return selectedSkyFactorPoints2d.map(({ value }) => {
      return value
    })
  }, [selectedSkyFactorPoints2d])

  const floorOptions = React.useMemo(() => {
    const { building_floor_infos } = volume_check_detail
    return Array.from(Array(floors.length).keys())
      .map((idx) => idx + 1)
      .filter((floorNumber) => {
        return building_floor_infos.some((b) => b.floor_number === floorNumber)
      })
      .map((floorNumber) => {
        return {
          value: floorNumber,
          label: `${floorNumber}F`,
        }
      })
  }, [floors, volume_check_detail])

  React.useEffect(() => {
    if (floorOptions.length > 0) {
      setSelectedFloor(floorOptions[0])
    }
  }, [floorOptions])

  const [propertyShape, setPropertyShape] = React.useState<string>(null)
  const responseArea = volume_check_detail.best_response_area

  const handleDownloadDxf = React.useCallback(() => {
    if (svg !== null) {
      const address = volume_check_detail.base_infos['address']
      const dwg = svg2dxf(svg, true)
      const dxf = dwg.toDxfString()
      downloadText(`${address}.dxf`, dxf)
    }
  }, [svg, volume_check_detail.base_infos['address']])

  //JSON data of 3D build (GLTF format)
  const gltfJson = React.useRef<string>("");

  const handleSetGltfJson = (gltf: string): void => {
    gltfJson.current = gltf;
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', 'Volume Check', '詳細']}
          property_id={volume_check_detail.property.hashid}
          tab="volume_check"
        />
      }
      submenuCategory="properties"
      city={volume_check_detail.property.prefecture + volume_check_detail.property.city}
    >
      <div className="">
        <div className="flex ml-auto gap-1 pt-1 md:gap-2 md:pt-0">
          <div className="flex-auto w-2/3 overflow-y-auto h-[calc(100vh_-_103px)]">
            <div className="flex flex-wrap items-end justify-between pt-2">
              <div className="flex flex-wrap items-center gap-1 whitespace-nowrap"></div>
              <form
                className="mt-8"
                action={`/properties/${volume_check_detail.property.hashid}/volume_check_requests/${volume_check_detail.hashid}/upload_excel`}
                acceptCharset="UTF-8"
                method="post"
                encType="multipart/form-data"
              >
                <div className="flex ml-auto gap-1 pt-1 md:gap-2 md:pt-0">
                  <input type="hidden" name="id" id="id" value={volume_check_detail.hashid} />
                  <input
                    type="hidden"
                    name="property_id"
                    id="property_id"
                    value={volume_check_detail.property.hashid}
                  />
                  <input
                    type="hidden"
                    name="authenticity_token"
                    id="csrfToken"
                    value={csrfToken.content}
                  />
                  <AnchorButton
                    prefix={<ContentPasteSearchIcon fontSize="small" />}
                    outline
                    size="small"
                    variant="primary"
                    href={`/properties/${volume_check_detail.property.hashid}/volume_check_requests/new?ref=${volume_check_detail.hashid}`}
                  >
                    recheck
                  </AnchorButton>
                  <AnchorButton
                    className="text-primary-font cursor-pointer"
                    prefix={<DescriptionOutlinedIcon fontSize="small" />}
                    outline
                    size="small"
                    variant="primary"
                    onClick={() => {
                      propertyPlanSubmit()
                    }}
                  >
                    <span className="hidden md:inline">収支計画書</span>
                  </AnchorButton>
                </div>
              </form>
            </div>
            <div className="mt-1">
              <Table className="border-t border-b border-[#3885B0]">
                <div>
                  <Row label={<Th left={true}>作成日時</Th>}>
                    <Td>{volume_check_detail.base_infos['created_at']}</Td>
                  </Row>
                  <Row label={<Th left={true}>建築プラン</Th>}>
                    <Td>{volume_check_detail.base_infos['build_plan']}</Td>
                  </Row>
                  <Row label={<Th left={true}>案件名</Th>}>
                    <Td>{volume_check_detail.base_infos['proposal_plan']}</Td>
                  </Row>
                  <Row label={<Th left={true}>プラン名</Th>}>
                    <Td>{volume_check_detail.base_infos['plan_name']}</Td>
                  </Row>
                </div>
              </Table>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
                敷地情報
              </div>
              <Table className="border-t border-b border-[#3885B0]">
                <div>
                  <Row label={<Th left={true}>所在地</Th>}>
                    <Td>
                      <p className="whitespace-pre">{volume_check_detail.base_infos['address']}</p>
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        敷地面積
                      </Th>
                    }
                  >
                    <Td column={5}>{unit_m2(volume_check_detail.area_infos['area'])}</Td>
                    <Td column={5}>{unit_tsubo(volume_check_detail.area_infos['area_tsubo'])}</Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        有効敷地面積
                      </Th>
                    }
                  >
                    <Td column={5}>
                      {unit_m2(volume_check_detail.area_infos['effective_site_area'])}
                    </Td>
                    <Td column={5}>
                      {unit_tsubo(volume_check_detail.area_infos['effective_site_area_tsubo'])}
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        提供敷地面積
                      </Th>
                    }
                  >
                    <Td column={5}>
                      {unit_m2(volume_check_detail.area_infos['provided_site_area'])}
                    </Td>
                    <Td column={5}>
                      {unit_tsubo(volume_check_detail.area_infos['provided_site_area_tsubo'])}
                    </Td>
                  </Row>
                </div>
              </Table>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
                敷地寸法
              </div>
              <Table className="border-t border-b border-[#3885B0]">
                {isLoaded &&
                  volume_check_detail.border_request_infos.map((border_info, index) => (
                    <div key={index} className="flex flex-wrap">
                      <Row
                        className="w-full md:w-1/3"
                        label={
                          <Th left={true} column={3}>
                            {border_info['line']}
                          </Th>
                        }
                      >
                        <Td column={3}>
                          {unit_meter(
                            computeDistance(
                              border_info['distance'].from,
                              border_info['distance'].to
                            )
                          )}
                        </Td>
                      </Row>
                      <Row
                        className="w-full md:w-1/3"
                        label={
                          <Th left={true} column={3}>
                            {border_info['border_type']}
                          </Th>
                        }
                      >
                        <Td column={3}>{unit_meter(border_info['width'])}</Td>
                      </Row>
                      <Row
                        className="w-full md:w-1/3"
                        label={
                          <Th left={true} column={3}>
                            -
                          </Th>
                        }
                      >
                        <Td column={3}>-</Td>
                      </Row>
                    </div>
                  ))}
              </Table>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
                都市計画関係
              </div>
              <Table className="border-t border-b border-[#3885B0]">
                <div>
                  <Row className="w-full" label={<Th left={true} column={1}></Th>}>
                    <Td column={4}>領域1</Td>
                    <Td column={4}>領域2</Td>
                    <Td column={4}>領域3</Td>
                    <Td column={4}>領域4</Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        領域面積
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {unit_m2(volume_check_detail.city_planning_infos[0]['area'])}
                    </Td>
                    <Td column={4}>
                      {unit_m2(volume_check_detail.city_planning_infos[1]['area'])}
                    </Td>
                    <Td column={4}>
                      {unit_m2(volume_check_detail.city_planning_infos[2]['area'])}
                    </Td>
                    <Td column={4}>
                      {unit_m2(volume_check_detail.city_planning_infos[3]['area'])}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        用途地域
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[0]['area_of_use_code']}
                    </Td>
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[1]['area_of_use_code']}
                    </Td>
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[2]['area_of_use_code']}
                    </Td>
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[3]['area_of_use_code']}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        防火地域
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[0]['fire_protection_code']}
                    </Td>
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[1]['fire_protection_code']}
                    </Td>
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[2]['fire_protection_code']}
                    </Td>
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[3]['fire_protection_code']}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        高度地区
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[0]['altitude_area_code']}
                    </Td>
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[1]['altitude_area_code']}
                    </Td>
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[2]['altitude_area_code']}
                    </Td>
                    <Td column={4}>
                      {volume_check_detail.city_planning_infos[3]['altitude_area_code']}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        日影規制
                      </Th>
                    }
                  >
                    <Td column={4}>{volume_check_detail.city_planning_infos[0]['shade_info']}</Td>
                    <Td column={4}>{volume_check_detail.city_planning_infos[1]['shade_info']}</Td>
                    <Td column={4}>{volume_check_detail.city_planning_infos[2]['shade_info']}</Td>
                    <Td column={4}>{volume_check_detail.city_planning_infos[3]['shade_info']}</Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        基準建ぺい率
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {unit_int_pct(
                        volume_check_detail.city_planning_infos[0][
                        'standard_building_coverage_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        volume_check_detail.city_planning_infos[1][
                        'standard_building_coverage_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        volume_check_detail.city_planning_infos[2][
                        'standard_building_coverage_ratio'
                        ]
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        volume_check_detail.city_planning_infos[3][
                        'standard_building_coverage_ratio'
                        ]
                      )}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        基準容積率
                      </Th>
                    }
                  >
                    <Td column={4}>
                      {unit_int_pct(
                        volume_check_detail.city_planning_infos[0]['standard_floor_area_ratio']
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        volume_check_detail.city_planning_infos[1]['standard_floor_area_ratio']
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        volume_check_detail.city_planning_infos[2]['standard_floor_area_ratio']
                      )}
                    </Td>
                    <Td column={4}>
                      {unit_int_pct(
                        volume_check_detail.city_planning_infos[3]['standard_floor_area_ratio']
                      )}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        採用建ぺい率
                      </Th>
                    }
                  >
                    <Td>
                      {unit_pct(
                        volume_check_detail.city_planning_infos[0]['adopted_building_area_ratio']
                      )}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        採用容積率
                      </Th>
                    }
                  >
                    <Td>
                      {unit_pct(
                        volume_check_detail.city_planning_infos[0]['adopted_floor_area_ratio']
                      )}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        許容建ぺい率
                      </Th>
                    }
                  >
                    <Td>
                      {unit_pct(
                        volume_check_detail.city_planning_infos[0][
                        'allowable_building_coverage_ratio'
                        ]
                      )}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        許容容積率
                      </Th>
                    }
                  >
                    <Td>
                      {unit_pct(
                        volume_check_detail.city_planning_infos[0]['allowable_floor_area_ratio']
                      )}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        許容建築面積
                      </Th>
                    }
                  >
                    <Td>
                      {unit_m2(
                        volume_check_detail.city_planning_infos[0]['allowable_building_area']
                      )}
                    </Td>
                  </Row>
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        許容容積対象床面積
                      </Th>
                    }
                  >
                    <Td>
                      {unit_m2(volume_check_detail.city_planning_infos[0]['allowable_floor_area'])}
                    </Td>
                  </Row>
                </div>
              </Table>
            </div>

            <div className="mt-5">
              <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
                設計概要
              </div>
              <Table className="border-t border-b border-[#3885B0]">
                <div>
                  <Row label={<Th left={true}>建物用途</Th>}>
                    <Td>
                      {jigyoplan['CV13'] || volume_check_detail.plan_infos['building_for_use']}
                    </Td>
                  </Row>
                  <Row label={<Th left={true}>住戸数</Th>}>
                    <Td>
                      {jigyoplan['CV14'] || volume_check_detail.plan_infos['number_of_units']}
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        建物規模
                      </Th>
                    }
                  >
                    <Td column={5}>
                      {jigyoplan['CV15']
                        ? `地上${jigyoplan['CV15']}階`
                        : volume_check_detail.plan_infos['above_ground_building_floors']}
                    </Td>
                    <Td column={5}>
                      {jigyoplan['CV16']
                        ? `地下${jigyoplan['CV15']}階`
                        : volume_check_detail.plan_infos['under_ground_building_floors']}
                    </Td>
                  </Row>
                  <Row label={<Th left={true}>建物構造</Th>}>
                    <Td>
                      {jigyoplan['CV17'] || volume_check_detail.plan_infos['building_structure']}
                    </Td>
                  </Row>
                  <Row label={<Th left={true}>建物高さ</Th>}>
                    <Td>
                      {unit_meter(
                        jigyoplan['CV18'] || volume_check_detail.plan_infos['building_height']
                      )}
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        建築面積
                      </Th>
                    }
                  >
                    <Td column={5}>
                      {unit_m2(
                        jigyoplan['CV20'] || volume_check_detail.plan_infos['building_area']
                      )}
                    </Td>
                    <Td column={5}>
                      {unit_tsubo(
                        jigyoplan['CV20'] * 0.3025 ||
                        volume_check_detail.plan_infos['building_area_tsubo']
                      )}
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        塔状比
                      </Th>
                    }
                  >
                    <Td column={5}>
                      {unit_num(jigyoplan['CV19'] || volume_check_detail.plan_infos['tower_ratio'])}
                    </Td>
                    <Td column={5}></Td>
                  </Row>
                  <Row label={<Th left={true}>計画建ぺい率</Th>}>
                    <Td>
                      {unit_pct(
                        jigyoplan['CV21'] || volume_check_detail.plan_infos['building_area_ratio']
                      )}
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        法定延床面積
                      </Th>
                    }
                  >
                    <Td column={5}>
                      {unit_m2(
                        jigyoplan['CV22'] ||
                        volume_check_detail.plan_infos['legal_total_floor_area']
                      )}
                    </Td>
                    <Td column={5}>
                      {unit_tsubo(
                        jigyoplan['CV22'] * 0.3025 ||
                        volume_check_detail.plan_infos['legal_total_floor_area_tsubo']
                      )}
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        容積対象床面積
                      </Th>
                    }
                  >
                    <Td column={5}>
                      {unit_m2(
                        jigyoplan['CV23'] ||
                        volume_check_detail.plan_infos['total_area_subject_to_area_floor']
                      )}
                    </Td>
                    <Td column={5}>
                      {unit_tsubo(
                        jigyoplan['CV23'] * 0.3025 ||
                        volume_check_detail.plan_infos['total_area_subject_to_area_floor_tsubo']
                      )}
                    </Td>
                  </Row>
                  <Row label={<Th left={true}>計画容積率</Th>}>
                    <Td>
                      {unit_pct(
                        jigyoplan['CV25'] || volume_check_detail.plan_infos['floor_area_ratio']
                      )}
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        施工床面積
                      </Th>
                    }
                  >
                    <Td column={5}>
                      {unit_m2(
                        jigyoplan['CV26'] ||
                        volume_check_detail.plan_infos['total_construction_floor_area']
                      )}
                    </Td>
                    <Td column={5}>
                      {unit_tsubo(
                        jigyoplan['CV26'] * 0.3025 ||
                        volume_check_detail.plan_infos['total_construction_floor_area_tsubo']
                      )}
                    </Td>
                  </Row>
                  <Row
                    label={
                      <Th left={true} column={1}>
                        総専有面積
                      </Th>
                    }
                  >
                    <Td column={5}>
                      {unit_m2(
                        jigyoplan['CV27'] || volume_check_detail.plan_infos['total_exclusive_area']
                      )}
                    </Td>
                    <Td column={5}>
                      {unit_tsubo(
                        jigyoplan['CV27'] * 0.3025 ||
                        volume_check_detail.plan_infos['total_exclusive_area_tsubo']
                      )}
                    </Td>
                  </Row>
                </div>
              </Table>
            </div>

            <div className="my-5">
              <Table className="border-t border-b border-[#3885B0]">
                <div>
                  <Row className="w-full" label={<Th left={true} column={1}></Th>}>
                    <Td column={6}>階高</Td>
                    <Td column={6}>容積対象床面積_専有</Td>
                    <Td column={6}>容積対象床面積_共用</Td>
                    <Td column={6}>その他面積</Td>
                    <Td column={6}>施工床面積</Td>
                  </Row>
                  {jigyoplan_floor_infos &&
                    jigyoplan_floor_infos.map((floor_info, index) => (
                      <Row
                        key={index}
                        className="w-full"
                        label={
                          <Th left={true} column={1}>
                            {index + 1}F
                          </Th>
                        }
                      >
                        <Td column={6}>{unit_meter(floor_info['floor_height'])}</Td>
                        <Td column={6}>{unit_m2(floor_info['exclusive_area'])}</Td>
                        <Td column={6}>{unit_m2(floor_info['shared_area'])}</Td>
                        <Td column={6}>{unit_m2(floor_info['area_not_subject_to_area_floor'])}</Td>
                        <Td column={6}>{unit_m2(floor_info['construction_floor_area'])}</Td>
                      </Row>
                    ))}
                  {!jigyoplan_floor_infos &&
                    volume_check_detail.building_floor_infos.map((floor_info, index) => (
                      <Row
                        key={index}
                        className="w-full"
                        label={
                          <Th left={true} column={1}>
                            {floor_info['floor_number']}F
                          </Th>
                        }
                      >
                        <Td column={6}>{unit_meter(floor_info['floor_height'])}</Td>
                        <Td column={6}>{unit_m2(floor_info['exclusive_area'])}</Td>
                        <Td column={6}>{unit_m2(floor_info['shared_area'])}</Td>
                        <Td column={6}>{unit_m2(floor_info['area_not_subject_to_area_floor'])}</Td>
                        <Td column={6}>{unit_m2(floor_info['construction_floor_area'])}</Td>
                      </Row>
                    ))}
                  <Row
                    className="w-full"
                    label={
                      <Th left={true} column={1}>
                        計
                      </Th>
                    }
                  >
                    <Td column={6}>
                      {unit_meter(
                        jigyoplan['building_height'] ||
                        volume_check_detail.building_total_floor_infos['building_height']
                      )}
                    </Td>
                    <Td column={6}>
                      {unit_m2(
                        jigyoplan['total_exclusive_area'] ||
                        volume_check_detail.building_total_floor_infos['total_exclusive_area']
                      )}
                    </Td>
                    <Td column={6}>
                      {unit_m2(
                        jigyoplan['total_common_area'] ||
                        volume_check_detail.building_total_floor_infos['total_common_area']
                      )}
                    </Td>
                    <Td column={6}>
                      {unit_m2(
                        jigyoplan['total_other_floor_area'] ||
                        volume_check_detail.building_total_floor_infos['total_other_floor_area']
                      )}
                    </Td>
                    <Td column={6}>
                      {unit_m2(
                        jigyoplan['total_construction_floor_area'] ||
                        volume_check_detail.building_total_floor_infos[
                        'total_construction_floor_area'
                        ]
                      )}
                    </Td>
                  </Row>
                </div>
              </Table>
            </div>
          </div>

          <div className="flex-auto w-1/3 overflow-y-auto my-2">
            <div className="relative border border-[#3885B0]" ref={mapRef}>
              {isLoaded && (
                <>
                  <GoogleMapViewer
                    polygons_api_base_url={polygons_api_base_url}
                    csrfToken={csrfToken.content}
                    mode="volume_detail"
                    property_id={Number(volume_check_detail.property?.id)}
                    volume_check_request_id={Number(volume_check_detail.id)}
                    lat={volume_check_detail.property.address_geocoding[0]}
                    lng={volume_check_detail.property.address_geocoding[1]}
                    areaTextFromResponse={responseArea}
                    areaShape={propertyShape}
                    onChangeShape={(shape) => {
                      setPropertyShape(shape)
                    }}
                    style={{
                      width: '100%',
                      height: isMapFullScreen ? '100%' : '320px',
                    }}
                    current_user={current_user}
                  />
                  <AnchorButton
                    className="absolute right-2 top-2 w-8 h-8 z-10"
                    onClick={isMapFullScreen ? exitMapFullScreen : triggerMapFullScreen}
                  >
                    {isMapFullScreen ? (
                      <FullscreenExitIcon fontSize="small" />
                    ) : (
                      <FullscreenIcon fontSize="small" />
                    )}
                  </AnchorButton>
                </>
              )}
            </div>
            <div className="relative mt-2 border border-[#3885B0] h-80" ref={dxfRef}>
              <div className="absolute left-2 top-2 z-10">
                {
                  <ReactSelect
                    options={skyFactorPointOptions}
                    value={selectedSkyFactorPoints}
                    onChange={setSelectedSkyFactorPoints}
                    isMulti
                  />
                }
              </div>
              {volume_check_detail.drawing_file !== undefined && (
                <ErrorBoundary fallback={<div />}>
                  <Drawing3dView
                    drawing={volume_check_detail.drawing_file}
                    selectedSkyFactor={filteredSkyFactorIndices}
                    handleSetGltfJson={handleSetGltfJson}
                    dxf_model={volume_check_detail.dxf_model}
                    isBirdCage={isBirdCage}
                    underGroundBuildingHeight={volume_check_detail.plan_infos['under_ground_building_height']}
                  />
                </ErrorBoundary>
              )}
              <AnchorButton
                className="absolute right-2 top-2 w-8 h-8 z-10"
                onClick={isDxfFullScreen ? exitDxfFullScreen : triggerDxfFullScreen}
              >
                {isDxfFullScreen ? (
                  <FullscreenExitIcon fontSize="small" />
                ) : (
                  <FullscreenIcon fontSize="small" />
                )}
              </AnchorButton>
              <AnchorButton
                className="absolute right-2 top-12 w-8 h-8 z-10"
                onClick={isBirdCage ? () => { setIsBirdCage(false) } : () => { setIsBirdCage(true) }}
              >
                {isBirdCage ? (
                  <VisibilityOffIcon fontSize="small" />
                ) : (
                  <VisibilityIcon fontSize="small" />
                )}
              </AnchorButton>
              <div className="absolute right-2 bottom-2 w-8 h-8 z-10">
                {isLoaded && propertyShape !== null && env_name !== 'production' && (
                  <>
                    <BuildingMapModal
                      gltfJson={gltfJson}
                      propertyShape={propertyShape}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="relative mt-2 border border-[#3885B0] h-80 bg-white" ref={drawingRef}>
              <div className="absolute left-2 top-2 z-10">
                {
                  <ReactSelect
                    options={floorOptions}
                    value={selectedFloor}
                    onChange={setSelectedFloor}
                  />
                }
              </div>
              {selectedFloor !== undefined && selectedFloor.value === 1 && (
                <div className="absolute left-2 top-12 z-10">
                  {
                    <ReactSelect
                      options={skyFactorPointOptions}
                      value={selectedSkyFactorPoints2d}
                      onChange={setSelectedSkyFactorPoints2d}
                      isMulti
                    />
                  }
                </div>
              )}
              {volume_check_detail.drawing_file !== undefined && (
                <ErrorBoundary fallback={<div />}>
                  <Drawing2dView
                    flipY
                    drawing={volume_check_detail.drawing_file}
                    floorIndex={selectedFloor !== undefined ? selectedFloor.value - 1 : undefined}
                    ref={(e) => {
                      if (e !== null) {
                        setSvg(e.svg)
                      }
                    }}
                    selectedSkyFactor={filteredSkyFactorIndices2d}
                  />
                </ErrorBoundary>
              )}
              <Button className="absolute right-2 bottom-2" onClick={handleDownloadDxf}>
                DXF
              </Button>
              <AnchorButton
                className="absolute right-2 top-2 w-8 h-8 z-10"
                onClick={isDrawingFullScreen ? exitDrawingFullScreen : triggerDrawingFullScreen}
              >
                {isDrawingFullScreen ? (
                  <FullscreenExitIcon fontSize="small" />
                ) : (
                  <FullscreenIcon fontSize="small" />
                )}
              </AnchorButton>
            </div>
          </div>
        </div>
      </div>
    </Base>
  )
}

export default VolumeCheckRequestDetailPage
