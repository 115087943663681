import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { RadioButton } from '@/components/RadioButton'

import { DropDown, Option, ChildOption } from '@/components/DropDown/Index'
import {
  buildingTypeSaleOptionsForSearch,
  buildingTypeRentOptionsForSearch,
  floorTypeOptions,
  statusOptionsForSearch,
  buildingStructureOptionsForSearch,
  radioOptions,
  orientationOptionsForSearch,
} from '@/models/marketDatum'
import {
  buildingStandardsLawArticlesOptionsForSearch,
  directionOptionsForSearch,
} from '@/models/road_access'
import {
  cammedFormat,
  dataPrecisionSetup,
  intFormat,
  searchYeildFormat,
} from '@/utils/cammedFormat'

type Props = {
  prefecture_options: Option[]
  transportation_options: Option[]
  station_options: ChildOption[]
  title: string
  page_type: string
  floor_plan_options: any[]
  contractor_options: any[]
  room_type_options: any[]
  is_properties: boolean
  search_location?: Location
}

type TownJson = {
  town: string
  town_kana: string
}[]

export const SearchModal: React.FC<Props> = ({
  title,
  prefecture_options,
  transportation_options,
  station_options,
  page_type,
  floor_plan_options,
  contractor_options,
  room_type_options,
  is_properties,
  search_location,
}) => {
  const forSale = page_type === 'for_sale'
  const forRent = page_type === 'for_rent'
  const [isOpen, setIsOpen] = React.useState(false)
  const [city_options, setCityOptions] = React.useState([])
  const [town_options, setTownOptions] = React.useState([])
  const [station_cv_options, setStationCvOptions] = React.useState([])
  const [transportation_cv_options, setTransportationCvOptions] = React.useState([])

  const [marketDatumSearchParams, setmarketDatumSearchParams] = React.useState({
    marketDatumPrefecture: { value: '', label: '' },
    marketDatumCity: { value: '', label: '' },
    marketDatumTown: { value: '', label: '' },
    marketDatumChome: '',
    marketDatumName: '',
    transportationsTransportation: { value: '', label: '' },
    transportationsNearestStation: { parent_value: '', value: '', label: '' },
    transportationsWalkingGteq: '',
    transportationsWalkingLteq: '',
    transportationsBusGteq: '',
    transportationsBusLteq: '',
    transportationsMinutesOnFootGteq: '',
    transportationsMinutesOnFootLteq: '',
    marketDatumPropertyTypeEq: [],
    marketDatumBuildingTypeEq: [],
    marketDatumStatusEq: '',
    marketDatumSalePriceGteq: '',
    marketDatumSalePriceLteq: '',
    marketDatumBuildingPriceGteq: '',
    marketDatumBuildingPriceLteq: '',
    marketDatumSaleProspectiveYieldGteq: '',
    marketDatumSaleProspectiveYieldLteq: '',
    marketDatumProspectiveYieldGteq: '',
    marketDatumProspectiveYieldLteq: '',
    marketDatumAreaM3Gteq: '',
    marketDatumAreaM3Lteq: '',
    marketDatumTotalFloorAreaGteq: '',
    marketDatumTotalFloorAreaLteq: '',
    marketDatumOccupiedAreaGteq: '',
    marketDatumOccupiedAreaLteq: '',
    marketDatumFloorPlanEqAddEmpty: [],
    marketDatumFloorTypeG: '',
    marketDatumFloorTypeL: '',
    marketDatumFloorG: '',
    marketDatumFloorL: '',
    marketDatumFloorsAboveGroundGteq: '',
    marketDatumFloorsAboveGroundLteq: '',
    marketDatumFloorsUnderGroundGteq: '',
    marketDatumFloorsUnderGroundLteq: '',
    marketDatumFloorsNumberOfUnitsGteq: '',
    marketDatumFloorsNumberOfUnitsLteq: '',
    marketDatumBuildingStructureEq: [],
    marketDatumBuildDateGteq: '',
    marketDatumBuildDateLteq: '',
    marketDatumContractorEq: [],
    marketDatumAutoLockEq: '',
    marketDatumElevatorEq: '',
    marketDatumContractDateGteq: '',
    marketDatumContractDateLteq: '',
    marketDatumPublicationDateGteq: '',
    marketDatumPublicationDateLteq: '',
    roadAccessesDirectionEq: [],
    roadAccessesWidthGteq: '',
    roadAccessesWidthLteq: '',
    roadAccessesBuildingStandardsLawArticlesEq: [],
    marketDatumRentGteq: '',
    marketDatumRentLteq: '',
    marketDatumMaintenanceFeeGteq: '',
    marketDatumMaintenanceFeeLteq: '',
    marketDatumRentMaintenanceFeeGteq: '',
    marketDatumRentMaintenanceFeeLteq: '',
    marketDatumRentMaintenanceFeeUnitPriceGteq: '',
    marketDatumRentMaintenanceFeeUnitPriceLteq: '',
    marketDatumRentMaintenanceFeeUnitPriceTsuboGteq: '',
    marketDatumRentMaintenanceFeeUnitPriceTsuboLteq: '',
    marketDatumSecurityDepositGteq: '',
    marketDatumSecurityDepositLteq: '',
    marketDatumKeyMoneyGteq: '',
    marketDatumKeyMoneyLteq: '',
    marketDatumRoomTypeEq: [],
    marketDatumOrientationEq: [],
    marketDatumBalconyEq: '',
    marketDatumFreeInternetEq: '',
    marketDatumFurnitureAndAppliancesEq: '',
    distance: '',
  })

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const prof = searchParams.get('q[prefecture_eq]') || ''
    const transp = searchParams.get('q[transportations_transportation_eq]') || ''
    const nearest = searchParams.get('q[transportations_nearest_station_eq]') || ''
    const city = searchParams.get('q[city_eq]') || ''
    const town = searchParams.get('q[town_eq]') || ''

    initTraOptions()
    getCity(prefecture_options[prof]?.label ?? '')
    getTown(prefecture_options[prof]?.label ?? '', city)

    const searchAddressListParam = searchParams.get('searchAddressList')
    const initialSearchAddressList = searchAddressListParam
      ? JSON.parse(searchAddressListParam)
      : []
    setAddressList(initialSearchAddressList)

    const searchTraListParam = searchParams.get('searchTraList')
    const initialSearchTraList = searchTraListParam ? JSON.parse(searchTraListParam) : []
    setTraList(initialSearchTraList)
    setmarketDatumSearchParams({
      marketDatumPrefecture: prefecture_options[prof] || { value: '', label: '' },
      marketDatumCity: { value: city, label: city },
      marketDatumTown: { value: town, label: town },
      marketDatumChome: searchParams.get('q[chome_cont]') || '',
      marketDatumName: searchParams.get('q[name_cont]') || '',
      transportationsTransportation: { value: transp, label: transp },
      transportationsNearestStation: { parent_value: transp, value: nearest, label: nearest },
      transportationsWalkingGteq: searchParams.get('q[transportations_walking_gteq]') || '',
      transportationsWalkingLteq: searchParams.get('q[transportations_walking_lteq]') || '',
      transportationsBusGteq: searchParams.get('q[transportations_bus_gteq]') || '',
      transportationsBusLteq: searchParams.get('q[transportations_bus_lteq]') || '',
      transportationsMinutesOnFootGteq:
        searchParams.get('q[transportations_minutes_on_foot_gteq]') || '',
      transportationsMinutesOnFootLteq:
        searchParams.get('q[transportations_minutes_on_foot_lteq]') || '',
      marketDatumPropertyTypeEq: searchParams.getAll('q[property_type_in][]') || [],
      marketDatumBuildingTypeEq: searchParams.getAll('q[building_type_in][]') || [],
      marketDatumStatusEq: searchParams.get('q[status_in]') || '',
      marketDatumSalePriceGteq: searchParams.get('q[sale_price_gteq]') || '',
      marketDatumSalePriceLteq: searchParams.get('q[sale_price_lteq]') || '',
      marketDatumBuildingPriceGteq: searchParams.get('q[building_price_gteq]') || '',
      marketDatumBuildingPriceLteq: searchParams.get('q[building_price_lteq]') || '',
      marketDatumSaleProspectiveYieldGteq:
        searchYeildFormat(searchParams.get('q[sale_prospective_yield_gteq]')) || '',
      marketDatumSaleProspectiveYieldLteq:
        searchYeildFormat(searchParams.get('q[sale_prospective_yield_lteq]')) || '',
      marketDatumProspectiveYieldGteq:
        searchYeildFormat(searchParams.get('q[prospective_yield_gteq]')) || '',
      marketDatumProspectiveYieldLteq:
        searchYeildFormat(searchParams.get('q[prospective_yield_lteq]')) || '',
      marketDatumAreaM3Gteq: searchParams.get('q[area_m3_gteq]') || '',
      marketDatumAreaM3Lteq: searchParams.get('q[area_m3_lteq]') || '',
      marketDatumTotalFloorAreaGteq: searchParams.get('q[total_floor_area_gteq]') || '',
      marketDatumTotalFloorAreaLteq: searchParams.get('q[total_floor_area_lteq]') || '',
      marketDatumOccupiedAreaGteq: searchParams.get('q[occupied_area_gteq]') || '',
      marketDatumOccupiedAreaLteq: searchParams.get('q[occupied_area_lteq]') || '',
      marketDatumFloorPlanEqAddEmpty: searchParams.getAll('q[floor_plan_add_empty][]') || [],
      marketDatumFloorTypeG: searchParams.get('q[floor_type_g]') || 'above_ground',
      marketDatumFloorTypeL: searchParams.get('q[floor_type_l]') || 'above_ground',
      marketDatumFloorG: searchParams.get('q[floor_g]') || '',
      marketDatumFloorL: searchParams.get('q[floor_l]') || '',
      marketDatumFloorsAboveGroundGteq: searchParams.get('q[floors_above_ground_gteq]') || '',
      marketDatumFloorsAboveGroundLteq: searchParams.get('q[floors_above_ground_lteq]') || '',
      marketDatumFloorsUnderGroundGteq: searchParams.get('q[floors_under_ground_gteq]') || '',
      marketDatumFloorsUnderGroundLteq: searchParams.get('q[floors_under_ground_lteq]') || '',
      marketDatumFloorsNumberOfUnitsGteq: searchParams.get('q[number_of_units_gteq]') || '',
      marketDatumFloorsNumberOfUnitsLteq: searchParams.get('q[number_of_units_lteq]') || '',
      marketDatumBuildingStructureEq: searchParams.getAll('q[building_structure_in][]') || [],
      marketDatumBuildDateGteq: searchParams.get('q[build_date_gteq]') || '',
      marketDatumBuildDateLteq: searchParams.get('q[build_date_lteq]') || '',
      marketDatumContractorEq:
        searchParams.getAll('q[contractor_in][]').filter((v) => v !== '') || [],
      marketDatumAutoLockEq: searchParams.get('q[auto_lock_in]') || '',
      marketDatumElevatorEq: searchParams.get('q[elevator_in]') || '',
      marketDatumContractDateGteq: searchParams.get('q[contract_date_gteq]') || '',
      marketDatumContractDateLteq: searchParams.get('q[contract_date_lteq]') || '',
      marketDatumPublicationDateGteq: searchParams.get('q[publication_date_gteq]') || '',
      marketDatumPublicationDateLteq: searchParams.get('q[publication_date_lteq]') || '',
      roadAccessesDirectionEq: searchParams.getAll('q[road_accesses_direction_in][]') || [],
      roadAccessesWidthGteq: searchParams.get('q[road_accesses_width_gteq]') || '',
      roadAccessesWidthLteq: searchParams.get('q[road_accesses_width_lteq]') || '',
      roadAccessesBuildingStandardsLawArticlesEq:
        searchParams.getAll('q[road_accesses_building_standards_law_articles_in][]') || [],
      marketDatumRentGteq: searchParams.get('q[rent_gteq]') || '',
      marketDatumRentLteq: searchParams.get('q[rent_lteq]') || '',
      marketDatumMaintenanceFeeGteq: searchParams.get('q[maintenance_fee_gteq]') || '',
      marketDatumMaintenanceFeeLteq: searchParams.get('q[maintenance_fee_lteq]') || '',
      marketDatumRentMaintenanceFeeGteq: searchParams.get('q[rent_maintenance_fee_gteq]') || '',
      marketDatumRentMaintenanceFeeLteq: searchParams.get('q[rent_maintenance_fee_lteq]') || '',
      marketDatumRentMaintenanceFeeUnitPriceGteq:
        searchParams.get('q[rent_maintenance_fee_unit_price_gteq]') || '',
      marketDatumRentMaintenanceFeeUnitPriceLteq:
        searchParams.get('q[rent_maintenance_fee_unit_price_lteq]') || '',
      marketDatumRentMaintenanceFeeUnitPriceTsuboGteq:
        searchParams.get('q[rent_maintenance_fee_unit_price_tsubo_gteq]') || '',
      marketDatumRentMaintenanceFeeUnitPriceTsuboLteq:
        searchParams.get('q[rent_maintenance_fee_unit_price_tsubo_lteq]') || '',
      marketDatumSecurityDepositGteq: searchParams.get('q[security_deposit_gteq]') || '',
      marketDatumSecurityDepositLteq: searchParams.get('q[security_deposit_lteq]') || '',
      marketDatumKeyMoneyGteq: searchParams.get('q[key_money_gteq]') || '',
      marketDatumKeyMoneyLteq: searchParams.get('q[key_money_lteq]') || '',
      marketDatumRoomTypeEq: searchParams.getAll('q[room_type_in][]') || [],
      marketDatumOrientationEq: searchParams.getAll('q[orientation_in][]') || [],
      marketDatumBalconyEq: searchParams.get('q[balcony_in]') || '',
      marketDatumFreeInternetEq: searchParams.get('q[free_internet_in]') || '',
      marketDatumFurnitureAndAppliancesEq: searchParams.get('q[furniture_and_appliances_in]') || '',
      distance: searchParams.get('distance') || '3000',
    })
  }, [])

  function getCity(prefecture: string) {
    if (prefecture === '') {
      setmarketDatumSearchParams({
        ...marketDatumSearchParams,
        marketDatumCity: { value: '', label: '' },
        marketDatumTown: { value: '', label: '' },
      })
      setCityOptions([])
      setTownOptions([])
      return
    }

    if (prefecture != '---' && prefecture != '') {
      fetch('/japanese-addresses/ja.json')
        .then((response) => response.json())
        .then((json) => {
          const citys: string[] = json[prefecture]
          const city_options = citys.map((city: string) => {
            const option = { value: city, label: city }
            return option
          })
          setCityOptions(city_options)
        })
        .catch((e) => {
          console.error(e)
          alert('市区町村データの取得に失敗しました。')
        })
    } else {
      setCityOptions([])
      setTownOptions([])
    }
  }
  function getTown(prefecture: string, city: string) {
    if (prefecture !== '' && city !== '') {
      fetch(`/japanese-addresses/ja/${prefecture}/${city}.json`)
        .then((response) => response.json())
        .then((json: TownJson) => {
          const town_options = json.map((town) => {
            const option = { value: town['town'], label: town['town'] }
            return option
          })
          setTownOptions(town_options)
        })
        .catch(() => {
          alert('町名データの取得に失敗しました。')
        })
    } else {
      setTownOptions([])
    }
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function onChangePref(value: Option | null) {
    setCityOptions([])
    setTownOptions([])
    if (value === null) {
      setmarketDatumSearchParams({
        ...marketDatumSearchParams,
        marketDatumPrefecture: {
          value: '',
          label: '',
        },
        marketDatumCity: {
          value: '',
          label: '',
        },
        marketDatumTown: {
          value: '',
          label: '',
        },
        marketDatumChome: '',
        marketDatumName: '',
      })
    } else {
      setmarketDatumSearchParams({
        ...marketDatumSearchParams,
        marketDatumPrefecture: {
          value: value.value.toString(),
          label: value.label,
        },
      })
      getCity(value && value.label)
    }
  }

  function onChangeCity(value: Option | null) {
    setTownOptions([])

    if (value === null) {
      setmarketDatumSearchParams({
        ...marketDatumSearchParams,
        marketDatumCity: {
          value: '',
          label: '',
        },
        marketDatumTown: {
          value: '',
          label: '',
        },
        marketDatumChome: '',
        marketDatumName: '',
      })
    } else {
      setmarketDatumSearchParams({
        ...marketDatumSearchParams,
        marketDatumCity: {
          value: value.value.toString(),
          label: value.label,
        },
      })
      getTown(marketDatumSearchParams.marketDatumPrefecture.label, value.value.toString())
    }
  }

  function onChangeTown(value: Option | null) {
    setmarketDatumSearchParams({
      ...marketDatumSearchParams,
      marketDatumTown: {
        value: value ? value.value.toString() : '',
        label: value ? value.label : '',
      },
    })
  }

  function onChangeTransportation(value: Option | null) {
    setStationCvOptions([])
    if (value === null) {
      setmarketDatumSearchParams({
        ...marketDatumSearchParams,
        transportationsTransportation: {
          value: '',
          label: '',
        },
        transportationsNearestStation: {
          parent_value: '',
          value: '',
          label: '',
        },
      })
      initTraOptions()
    } else {
      setmarketDatumSearchParams({
        ...marketDatumSearchParams,
        transportationsTransportation: {
          value: value.value.toString(),
          label: value.label,
        },
        transportationsNearestStation: {
          parent_value: '',
          value: '',
          label: '',
        },
      })
      getStation(value && value.label)
    }
  }

  function onChangeStation(value: ChildOption | null) {
    if (value === null) {
      setmarketDatumSearchParams({
        ...marketDatumSearchParams,
        transportationsNearestStation: {
          parent_value: '',
          value: '',
          label: '',
        },
      })
    } else {
      setmarketDatumSearchParams({
        ...marketDatumSearchParams,
        transportationsNearestStation: {
          parent_value: value.parent_value,
          value: value.value.toString(),
          label: value.label,
        },
      })
    }
  }

  function getStation(transportation: string) {
    if (transportation != '---' && transportation != '') {
      const trafiltered = station_options.filter((item) => item.parent_value === transportation)
      setStationCvOptions(trafiltered)
    } else {
      initTraOptions()
    }
  }

  function initTraOptions() {
    setStationCvOptions(station_options)
    setTransportationCvOptions(transportation_options)
  }

  const [searchAddressList, setAddressList] = React.useState([])
  const [searchTraList, setTraList] = React.useState([])

  const addSearchTraCondition = () => {
    const newCondition = {
      transportation: marketDatumSearchParams.transportationsTransportation.label,
      nearest_station: marketDatumSearchParams.transportationsNearestStation.label,
      walking_g: marketDatumSearchParams.transportationsWalkingGteq,
      walking_l: marketDatumSearchParams.transportationsWalkingLteq,
      bus_g: marketDatumSearchParams.transportationsBusGteq,
      bus_l: marketDatumSearchParams.transportationsBusLteq,
      minutes_on_foot_g: marketDatumSearchParams.transportationsMinutesOnFootGteq,
      minutes_on_foot_l: marketDatumSearchParams.transportationsMinutesOnFootLteq,
    }

    const isEmptyCondition = Object.values(newCondition).every((value) => !value)
    if (isEmptyCondition) {
      return
    }

    const updatedSearchTraList = [...searchTraList, newCondition]
    setTraList(updatedSearchTraList)
    setmarketDatumSearchParams((prevState) => ({
      ...prevState,
      transportationsTransportation: { value: '', label: '' },
      transportationsNearestStation: { parent_value: '', value: '', label: '' },
      transportationsWalkingGteq: '',
      transportationsWalkingLteq: '',
      transportationsBusGteq: '',
      transportationsBusLteq: '',
      transportationsMinutesOnFootGteq: '',
      transportationsMinutesOnFootLteq: '',
    }))
  }

  const removeSearchTraCondition = (index) => {
    const updatedList = searchTraList.filter((_, i) => i !== index)
    setTraList(updatedList)
  }
  const addSearchCondition = () => {
    const newCondition = {
      prefecture: marketDatumSearchParams.marketDatumPrefecture.label,
      city: marketDatumSearchParams.marketDatumCity.label,
      town: marketDatumSearchParams.marketDatumTown.label,
      chome: marketDatumSearchParams.marketDatumChome,
      name: marketDatumSearchParams.marketDatumName,
    }

    const isEmptyCondition = Object.values(newCondition).every((value) => !value)
    if (isEmptyCondition) {
      return
    }

    const updatedSearchAddressList = [...searchAddressList, newCondition]
    setAddressList(updatedSearchAddressList)
    setmarketDatumSearchParams((prevState) => ({
      ...prevState,
      marketDatumPrefecture: { value: '', label: '' },
      marketDatumCity: { value: '', label: '' },
      marketDatumTown: { value: '', label: '' },
      marketDatumChome: '',
      marketDatumName: '',
    }))
    onChangePref(null)
  }

  const removeSearchCondition = (index) => {
    const updatedList = searchAddressList.filter((_, i) => i !== index)
    setAddressList(updatedList)
  }

  // クエリパラメータで親コンポーネントにフォームデータを渡す
  const handleSubmit = (event) => {
    event.preventDefault()
    const params = new URLSearchParams()
    const parentParams = new URLSearchParams(search_location.search)
    const formData = new FormData(event.target)
    const tabParam = parentParams.get('tab')
    const indexParam = parentParams.get('tab_index')
    params.append('tab', tabParam)

    if (indexParam) {
      params.append('tab_index', indexParam)
    }

    for (const param of formData.entries()) {
      params.append(param[0], param[1])
    }
    params.append('is_search', 'true')
    location.href = `${search_location.origin + search_location.pathname}?${params.toString()}`
  }
  const resetpage = () => {
    // 検索用パラムを削除する
    const params = new URLSearchParams(search_location.search)
    const newParams = new URLSearchParams()
    // data_type、tab、tab_index以外の検索用パラメーターを全て削除する
    params.forEach((value, key) => {
      if (key === 'data_type' || key === 'tab' || key === 'tab_index') {
        newParams.append(key, value)
      }
    })
    return `${search_location.origin + search_location.pathname + '?' + newParams.toString()}`
  }
  return (
    <>
      <div className="">
        <button type="button" onClick={openModal}>
          <AnchorButton
            className="text-primary-font"
            prefix={<SearchIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
          >
            <span className="hidden md:inline">絞り込み</span>
          </AnchorButton>
        </button>
      </div>

      <Transition appear show={isOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                    {title}
                    <ClearIcon className="float-right cursor-pointer" onClick={closeModal} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>
                  <form
                    id="marketdata_search"
                    action={is_properties ? '' : '/market_data'}
                    acceptCharset="UTF-8"
                    onSubmit={is_properties ? handleSubmit : undefined}
                    method={is_properties ? '' : 'get'}
                    className={`pb-[70px] h-[90vh] overflow-y-scroll`}
                  >
                    <div className="mt-2">
                      <div className="p-4">
                        <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                          所在地
                        </div>
                        <div className="flex items-center">
                          <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                            都道府県
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <DropDown
                              className="text-sm"
                              name="q[prefecture_eq]"
                              id="q_prefecture_eq"
                              options={prefecture_options}
                              placeholder="---"
                              value={
                                marketDatumSearchParams.marketDatumPrefecture?.label !== '' &&
                                marketDatumSearchParams.marketDatumPrefecture
                              }
                              onChange={onChangePref}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                            市区町村
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <DropDown
                              className="text-sm"
                              name="q[city_eq]"
                              id="q_city_eq"
                              hasBlank
                              blankLabel="---"
                              options={city_options}
                              value={
                                marketDatumSearchParams.marketDatumCity.label !== '' &&
                                marketDatumSearchParams.marketDatumCity
                              }
                              onChange={onChangeCity}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                            町名
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <DropDown
                              className="text-sm"
                              name="q[town_eq]"
                              id="q_town_eq"
                              hasBlank
                              blankLabel="---"
                              options={town_options}
                              value={
                                marketDatumSearchParams.marketDatumTown.label !== '' &&
                                marketDatumSearchParams.marketDatumTown
                              }
                              onChange={onChangeTown}
                            />
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="py-5 pr-[6px] text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.3px] !leading-[1.25rem]">
                            丁目以降（住居表示）
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <div className="flex">
                              <Input
                                value={marketDatumSearchParams.marketDatumChome}
                                name="q[chome_cont]"
                                id="q_chome_cont"
                                className={'w-full'}
                                onChange={(e) =>
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    marketDatumChome: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                            建物名
                          </div>
                          <div className="py-2 px-4 w-3/4">
                            <div className="flex">
                              <Input
                                value={marketDatumSearchParams.marketDatumName}
                                name="q[name_cont]"
                                id="q_name_cont"
                                className={'w-full'}
                                onChange={(e) =>
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    marketDatumName: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          className="w-[100px] mb-2 self-center"
                          size="tiny"
                          type="button"
                          onClick={addSearchCondition}
                        >
                          所在地追加
                        </Button>
                        <div>
                          {searchAddressList.map((condition, index) => (
                            <div className="flex items-center justify-between" key={index}>
                              <div className="text-sm py-2 px-4 text-right whitespace-normal text-[#888] font-medium w-1/4">
                                所在地条件{index + 1}
                              </div>
                              <div className="flex justify-between items-center py-2 px-4 w-3/4">
                                <span className="text-sm text-[#888]">
                                  {condition.prefecture} {condition.city} {condition.town}{' '}
                                  {condition.chome} {condition.name}
                                </span>
                                <button
                                  type="button"
                                  className="align-middle text-[#777]"
                                  onClick={() => removeSearchCondition(index)}
                                >
                                  <DeleteOutlined fontSize="small" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                        <input
                          type="hidden"
                          name="searchAddressList"
                          value={JSON.stringify(searchAddressList)}
                        />
                      </div>
                    </div>
                    <div className="p-4 pt-0">
                      <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                        交通
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          路線名
                        </div>
                        <div className="py-2 px-4 w-3/4">
                          <DropDown
                            className="text-sm"
                            name="q[transportations_transportation_eq]"
                            id="q_transportations_transportation_eq"
                            options={transportation_cv_options}
                            placeholder="---"
                            value={
                              marketDatumSearchParams.transportationsTransportation?.label !== '' &&
                              marketDatumSearchParams.transportationsTransportation
                            }
                            onChange={onChangeTransportation}
                          />
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          駅名
                        </div>
                        <div className="py-2 px-4 w-3/4">
                          <DropDown
                            className="text-sm"
                            name="q[transportations_nearest_station_eq]"
                            id="q_transportations_nearest_station__eq"
                            hasBlank
                            blankLabel="---"
                            options={station_cv_options}
                            value={
                              marketDatumSearchParams.transportationsNearestStation.label !== '' &&
                              marketDatumSearchParams.transportationsNearestStation
                            }
                            onChange={onChangeStation}
                          />
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          徒歩
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              style={{ width: '142px' }}
                              value={marketDatumSearchParams.transportationsWalkingGteq}
                              name="q[transportations_walking_gteq]"
                              id="q_transportations_walking_gteq"
                              suffix="分"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  transportationsWalkingGteq: e.target.value,
                                })
                              }
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              style={{ width: '142px' }}
                              value={marketDatumSearchParams.transportationsWalkingLteq}
                              name="q[transportations_walking_lteq]"
                              id="q_transportations_walking_lteq"
                              suffix="分"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  transportationsWalkingLteq: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          バス
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              style={{ width: '142px' }}
                              value={marketDatumSearchParams.transportationsBusGteq}
                              name="q[transportations_bus_gteq]"
                              id="q_transportations_bus_gteq"
                              suffix="分"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  transportationsBusGteq: e.target.value,
                                })
                              }
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              style={{ width: '142px' }}
                              value={marketDatumSearchParams.transportationsBusLteq}
                              name="q[transportations_bus_lteq]"
                              id="q_transportations_bus_lteq"
                              suffix="分"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  transportationsBusLteq: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          下車徒歩
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              style={{ width: '142px' }}
                              value={marketDatumSearchParams.transportationsMinutesOnFootGteq}
                              name="q[transportations_minutes_on_foot_gteq]"
                              id="q_transportations_minutes_on_foot_gteq"
                              suffix="分"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  transportationsMinutesOnFootGteq: e.target.value,
                                })
                              }
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              style={{ width: '142px' }}
                              value={marketDatumSearchParams.transportationsMinutesOnFootLteq}
                              name="q[transportations_minutes_on_foot_lteq]"
                              id="q_transportations_minutes_on_foot_lteq"
                              suffix="分"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  transportationsMinutesOnFootLteq: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <Button
                        className="w-[100px] mb-2 self-center"
                        size="tiny"
                        type="button"
                        onClick={addSearchTraCondition}
                      >
                        交通追加
                      </Button>
                      <div>
                        {searchTraList.map((condition, index) => (
                          <div
                            className="flex items-center py-2 px-4 justify-content-end"
                            key={index}
                          >
                            <div className="text-sm py-2 px-4 text-right whitespace-normal text-[#888] font-medium w-1/4">
                              交通条件{index + 1}
                            </div>
                            <div className="d-flex flex-column">
                              <div className="text-sm text-[#888]">
                                {condition.transportation} {condition.nearest_station}
                              </div>
                              <div className="text-sm text-[#888]">
                                {(condition.walking_g || condition.walking_l) && <span>徒歩 </span>}
                                {condition.walking_g && (
                                  <span>{`${condition.walking_g}分以上 `}</span>
                                )}
                                {condition.walking_l && (
                                  <span> {` ${condition.walking_l}分以内`}</span>
                                )}
                              </div>
                              <div className="text-sm text-[#888]">
                                {(condition.bus_g || condition.bus_l) && <span>バス </span>}
                                {condition.bus_g && <span>{` ${condition.bus_g}分以上 `}</span>}
                                {condition.bus_l && <span> {` ${condition.bus_l}分以内`}</span>}
                              </div>
                              <div className="text-sm text-[#888]">
                                {(condition.minutes_on_foot_g || condition.minutes_on_foot_l) && (
                                  <span>下車徒歩 </span>
                                )}
                                {condition.minutes_on_foot_g && (
                                  <span>{` ${condition.minutes_on_foot_g}分以上 `}</span>
                                )}
                                {condition.minutes_on_foot_l && (
                                  <span> {` ${condition.minutes_on_foot_l}分以内`}</span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex flex-column ml-auto">
                              <button
                                type="button"
                                className="align-middle text-[#777]"
                                onClick={() => removeSearchTraCondition(index)}
                              >
                                <DeleteOutlined fontSize="small" />
                              </button>
                            </div>
                          </div>
                        ))}
                        <input
                          type="hidden"
                          name="searchTraList"
                          value={JSON.stringify(searchTraList)}
                        />
                      </div>
                    </div>
                    <div className="p-4 pt-0">
                      <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                        条件
                      </div>
                      {is_properties == true && (
                        <div className="flex items-center">
                          <div className="text-sm md:text-sm py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4">
                            絞り込み範囲
                          </div>
                          <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                            <div className="flex items-center">
                              <Input
                                className="md:w-[300px]"
                                value={marketDatumSearchParams.distance || ''}
                                name="marketDatumSearchParams.marketDatumFloorsNumberOfUnitsGteq"
                                id="distance"
                                prefix="半径"
                                defaultValue="3000"
                                suffix="m以内"
                                onChange={(e) =>
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    distance: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                    : ''
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    distance: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="distance"
                                id="distance"
                                value={
                                  marketDatumSearchParams.distance
                                    ? intFormat(String(marketDatumSearchParams.distance))
                                    : ''
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          物件種目
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <Select
                            multiple
                            className="text-sm bg-none h-[80px]"
                            name="q[property_type_in][]"
                            id="q_property_type_in"
                            options={[
                              {
                                text: '土地',
                                value: '1',
                              },
                              {
                                text: '土地＋建物（一棟）',
                                value: '2',
                              },
                              {
                                text: '土地＋建物（区分）',
                                value: '3',
                              },
                            ]}
                            value={marketDatumSearchParams.marketDatumPropertyTypeEq}
                            onChange={(e) => {
                              const values = Array.from(
                                e.target.selectedOptions,
                                (option) => option.value
                              )
                              setmarketDatumSearchParams({
                                ...marketDatumSearchParams,
                                marketDatumPropertyTypeEq: values,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          物件タイプ
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <Select
                            multiple
                            className="text-sm bg-none h-[80px]"
                            name="q[building_type_in][]"
                            id="q_building_type_in"
                            options={
                              forSale
                                ? buildingTypeSaleOptionsForSearch
                                : buildingTypeRentOptionsForSearch
                            }
                            value={marketDatumSearchParams.marketDatumBuildingTypeEq}
                            onChange={(e) => {
                              const values = Array.from(
                                e.target.selectedOptions,
                                (option) => option.value
                              )
                              setmarketDatumSearchParams({
                                ...marketDatumSearchParams,
                                marketDatumBuildingTypeEq: values,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          状態
                        </div>
                        <div className="py-2 px-4 w-3/4">
                          <Select
                            className="text-sm"
                            name="q[status_in]"
                            id="q_status_in_eq"
                            options={statusOptionsForSearch}
                            value={marketDatumSearchParams.marketDatumStatusEq}
                            hasBlank
                            blankLabel="募集中+成約"
                            onChange={(e) => {
                              setmarketDatumSearchParams({
                                ...marketDatumSearchParams,
                                marketDatumStatusEq: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                      {forSale && (
                        <div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              売出価格
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumSalePriceGteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSalePriceGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSalePriceGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[sale_price_gteq]"
                                  id="q_sale_price_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumSalePriceGteq
                                      ? intFormat(
                                          String(marketDatumSearchParams.marketDatumSalePriceGteq)
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumSalePriceLteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSalePriceLteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSalePriceLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[sale_price_lteq]"
                                  id="q_sale_price_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumSalePriceLteq
                                      ? intFormat(
                                          String(marketDatumSearchParams.marketDatumSalePriceLteq)
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              成約価格
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumBuildingPriceGteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumBuildingPriceGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumBuildingPriceGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[building_price_gteq]"
                                  id="q_building_price_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumBuildingPriceGteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumBuildingPriceGteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumBuildingPriceLteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumBuildingPriceLteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumBuildingPriceLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[building_price_lteq]"
                                  id="q_building_price_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumBuildingPriceLteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumBuildingPriceLteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              売出表面利回り
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    marketDatumSearchParams.marketDatumSaleProspectiveYieldGteq ||
                                    ''
                                  }
                                  suffix="%"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSaleProspectiveYieldGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSaleProspectiveYieldGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[sale_prospective_yield_gteq]"
                                  id="q_sale_prospective_yield_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumSaleProspectiveYieldGteq
                                      ? dataPrecisionSetup(
                                          String(
                                            Math.round(
                                              Number(
                                                marketDatumSearchParams.marketDatumSaleProspectiveYieldGteq
                                              ) * 100
                                            ) / 10000
                                          ),
                                          8,
                                          4
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    marketDatumSearchParams.marketDatumSaleProspectiveYieldLteq ||
                                    ''
                                  }
                                  suffix="%"
                                  placeholder=""
                                  onChange={(e) => {
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSaleProspectiveYieldLteq: e.target.value,
                                    })
                                  }}
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSaleProspectiveYieldLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[sale_prospective_yield_lteq]"
                                  id="q_sale_prospective_yield_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumSaleProspectiveYieldLteq
                                      ? dataPrecisionSetup(
                                          String(
                                            Math.round(
                                              Number(
                                                marketDatumSearchParams.marketDatumSaleProspectiveYieldLteq
                                              ) * 100
                                            ) / 10000
                                          ),
                                          8,
                                          4
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              成約表面利回り
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    marketDatumSearchParams.marketDatumProspectiveYieldGteq || ''
                                  }
                                  suffix="%"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumProspectiveYieldGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumProspectiveYieldGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[prospective_yield_gteq]"
                                  id="q_prospective_yield_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumProspectiveYieldGteq
                                      ? dataPrecisionSetup(
                                          String(
                                            Math.round(
                                              Number(
                                                marketDatumSearchParams.marketDatumProspectiveYieldGteq
                                              ) * 100
                                            ) / 10000
                                          ),
                                          8,
                                          4
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    marketDatumSearchParams.marketDatumProspectiveYieldLteq || ''
                                  }
                                  suffix="%"
                                  placeholder=""
                                  onChange={(e) => {
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumProspectiveYieldLteq: e.target.value,
                                    })
                                  }}
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? dataPrecisionSetup(e.target.value, 12, 2).toFixed(2)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumProspectiveYieldLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[prospective_yield_lteq]"
                                  id="q_prospective_yield_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumProspectiveYieldLteq
                                      ? dataPrecisionSetup(
                                          String(
                                            Math.round(
                                              Number(
                                                marketDatumSearchParams.marketDatumProspectiveYieldLteq
                                              ) * 100
                                            ) / 10000
                                          ),
                                          8,
                                          4
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {forRent && (
                        <div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              賃料
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumRentGteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[rent_gteq]"
                                  id="q_rent_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumRentGteq
                                      ? intFormat(
                                          String(marketDatumSearchParams.marketDatumRentGteq)
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumRentLteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentLteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[rent_lteq]"
                                  id="q_rent_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumRentLteq
                                      ? intFormat(
                                          String(marketDatumSearchParams.marketDatumRentLteq)
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              管理費
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumMaintenanceFeeGteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumMaintenanceFeeGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumMaintenanceFeeGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[maintenance_fee_gteq]"
                                  id="q_maintenance_fee_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumMaintenanceFeeGteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumMaintenanceFeeGteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumMaintenanceFeeLteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumMaintenanceFeeLteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumMaintenanceFeeLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[maintenance_fee_lteq]"
                                  id="q_maintenance_fee_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumMaintenanceFeeLteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumMaintenanceFeeLteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              賃料(管理費込み)
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumRentMaintenanceFeeGteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[rent_maintenance_fee_gteq]"
                                  id="q_rent_maintenance_fee_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeGteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumRentMaintenanceFeeGteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumRentMaintenanceFeeLteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeLteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[rent_maintenance_fee_lteq]"
                                  id="q_rent_maintenance_fee_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeLteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumRentMaintenanceFeeLteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              賃料(管理費込み）㎡単価
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceGteq
                                  }
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeUnitPriceGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeUnitPriceGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[rent_maintenance_fee_unit_price_gteq]"
                                  id="q_rent_maintenance_fee_unit_price_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceGteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceGteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceLteq
                                  }
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeUnitPriceLteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeUnitPriceLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[rent_maintenance_fee_unit_price_lteq]"
                                  id="q_rent_maintenance_fee_unit_price_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceLteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceLteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              賃料(管理費込み）坪単価
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceTsuboGteq
                                  }
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeUnitPriceTsuboGteq:
                                        e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeUnitPriceTsuboGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[rent_maintenance_fee_unit_price_tsubo_gteq]"
                                  id="q_rent_maintenance_fee_unit_price_tsubo_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceTsuboGteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceTsuboGteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceTsuboLteq
                                  }
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeUnitPriceTsuboLteq:
                                        e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRentMaintenanceFeeUnitPriceTsuboLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[rent_maintenance_fee_unit_price_tsubo_lteq]"
                                  id="q_rent_maintenance_fee_unit_price_tsubo_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceTsuboLteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumRentMaintenanceFeeUnitPriceTsuboLteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              敷金
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumSecurityDepositGteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSecurityDepositGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSecurityDepositGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[security_deposit_gteq]"
                                  id="q_security_deposit_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumSecurityDepositGteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumSecurityDepositGteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumSecurityDepositLteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSecurityDepositLteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumSecurityDepositLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[security_deposit_lteq]"
                                  id="q_security_deposit_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumSecurityDepositLteq
                                      ? intFormat(
                                          String(
                                            marketDatumSearchParams.marketDatumSecurityDepositLteq
                                          )
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              礼金
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <div className="flex items-center">
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumKeyMoneyGteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumKeyMoneyGteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumKeyMoneyGteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[key_money_gteq]"
                                  id="q_key_money_gteq"
                                  value={
                                    marketDatumSearchParams.marketDatumKeyMoneyGteq
                                      ? intFormat(
                                          String(marketDatumSearchParams.marketDatumKeyMoneyGteq)
                                        )
                                      : ''
                                  }
                                />
                                <span className="mx-2">〜</span>
                                <Input
                                  className="md:w-[300px]"
                                  value={marketDatumSearchParams.marketDatumKeyMoneyLteq}
                                  suffix="円"
                                  placeholder=""
                                  onChange={(e) =>
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumKeyMoneyLteq: e.target.value,
                                    })
                                  }
                                  onBlur={(e) => {
                                    const value = e.target.value
                                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                                      : ''
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumKeyMoneyLteq: value,
                                    })
                                  }}
                                />
                                <input
                                  type="hidden"
                                  name="q[key_money_lteq]"
                                  id="q_key_money_lteq"
                                  value={
                                    marketDatumSearchParams.marketDatumKeyMoneyLteq
                                      ? intFormat(
                                          String(marketDatumSearchParams.marketDatumKeyMoneyLteq)
                                        )
                                      : ''
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          敷地面積
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              style={{ width: '142px' }}
                              suffix="㎡"
                              value={marketDatumSearchParams.marketDatumAreaM3Gteq}
                              name="q[area_m3_gteq]"
                              id="q_area_m3_gteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumAreaM3Gteq: e.target.value,
                                })
                              }
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              style={{ width: '142px' }}
                              suffix="㎡"
                              value={marketDatumSearchParams.marketDatumAreaM3Lteq}
                              name="q[area_m3_lteq]"
                              id="q_area_m3_lteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumAreaM3Lteq: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          延床面積
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              style={{ width: '142px' }}
                              suffix="㎡"
                              value={marketDatumSearchParams.marketDatumTotalFloorAreaGteq}
                              name="q[total_floor_area_gteq]"
                              id="q_total_floor_area_gteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumTotalFloorAreaGteq: e.target.value,
                                })
                              }
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              style={{ width: '142px' }}
                              suffix="㎡"
                              value={marketDatumSearchParams.marketDatumTotalFloorAreaLteq}
                              name="q[total_floor_area_lteq]"
                              id="q_total_floor_area_lteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumTotalFloorAreaLteq: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          専有面積
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              style={{ width: '142px' }}
                              suffix="㎡"
                              value={marketDatumSearchParams.marketDatumOccupiedAreaGteq}
                              name="q[occupied_area_gteq]"
                              id="q_occupied_area_gteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumOccupiedAreaGteq: e.target.value,
                                })
                              }
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              style={{ width: '142px' }}
                              suffix="㎡"
                              value={marketDatumSearchParams.marketDatumOccupiedAreaLteq}
                              name="q[occupied_area_lteq]"
                              id="q_occupied_area_lteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumOccupiedAreaLteq: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          間取り
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <Select
                            multiple
                            hasBlank
                            blankLabel=""
                            className="text-sm bg-none h-[80px]"
                            name="q[floor_plan_add_empty][]"
                            id="q_floor_plan_add_empty"
                            options={floor_plan_options}
                            value={marketDatumSearchParams.marketDatumFloorPlanEqAddEmpty}
                            onChange={(e) => {
                              const values = Array.from(
                                e.target.selectedOptions,
                                (option) => option.value
                              )
                              if (values.length === 1 && values[0] === '') {
                                values.push('')
                              }
                              setmarketDatumSearchParams({
                                ...marketDatumSearchParams,
                                marketDatumFloorPlanEqAddEmpty: values,
                              })
                            }}
                          />
                        </div>
                      </div>
                      {forRent && (
                        <div>
                          <div>
                            <div className="flex items-center">
                              <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                                部屋タイプ
                              </div>
                              <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                                <Select
                                  multiple
                                  className="text-sm bg-none h-[80px]"
                                  name="q[room_type_in][]"
                                  id="q_room_type_in"
                                  options={room_type_options}
                                  value={marketDatumSearchParams.marketDatumRoomTypeEq}
                                  onChange={(e) => {
                                    const values = Array.from(
                                      e.target.selectedOptions,
                                      (option) => option.value
                                    )
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumRoomTypeEq: values,
                                    })
                                  }}
                                />
                              </div>
                            </div>

                            <div className="flex items-center">
                              <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                                開口向き
                              </div>
                              <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                                <Select
                                  multiple
                                  className="text-sm bg-none h-[80px]"
                                  name="q[orientation_in][]"
                                  id="q_orientation_in"
                                  options={orientationOptionsForSearch}
                                  value={marketDatumSearchParams.marketDatumOrientationEq}
                                  onChange={(e) => {
                                    const values = Array.from(
                                      e.target.selectedOptions,
                                      (option) => option.value
                                    )
                                    setmarketDatumSearchParams({
                                      ...marketDatumSearchParams,
                                      marketDatumOrientationEq: values,
                                    })
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          所在階
                        </div>
                        <div className="py-2 px-4 w-3/4">
                          <div className="flex items-center">
                            {floorTypeOptions.map((item, i) => (
                              <RadioButton
                                className="px-4"
                                key={i}
                                name="q[floor_type_g]"
                                id={`q_floor_type_g_${i}`}
                                text={item.text}
                                value={item.value}
                                checked={
                                  item.value === marketDatumSearchParams.marketDatumFloorTypeG
                                }
                                onChange={(e) => {
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    marketDatumFloorTypeG: e.target.value,
                                  })
                                }}
                              />
                            ))}
                            <span className="mx-10"></span>
                            {floorTypeOptions.map((item, i) => (
                              <RadioButton
                                className="px-4"
                                key={i}
                                name="q[floor_type_l]"
                                id={`q_floor_type_l_${i}`}
                                text={item.text}
                                value={item.value}
                                checked={
                                  item.value === marketDatumSearchParams.marketDatumFloorTypeL
                                }
                                onChange={(e) => {
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    marketDatumFloorTypeL: e.target.value,
                                  })
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-1 px-4 w-1/4"></div>
                        <div className="py-2 px-4 w-3/4">
                          <div className="flex items-center">
                            <Input
                              className="md:w-[300px]"
                              value={marketDatumSearchParams.marketDatumFloorG || ''}
                              name="marketDatumSearchParams.marketDatumFloorG"
                              id="q_floor_g"
                              suffix="階"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorG: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                  : ''
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorG: value,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="q[floor_g]"
                              id="q_floor_g"
                              value={
                                marketDatumSearchParams.marketDatumFloorG
                                  ? intFormat(String(marketDatumSearchParams.marketDatumFloorG))
                                  : ''
                              }
                            />
                            <span className="mx-2">〜</span>

                            <Input
                              className="md:w-[300px]"
                              value={marketDatumSearchParams.marketDatumFloorL || ''}
                              name="marketDatumSearchParams.marketDatumFloorL"
                              id="q_floor_g"
                              suffix="階"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorL: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                  : ''
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorL: value,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="q[floor_l]"
                              id="q_floor_l"
                              value={
                                marketDatumSearchParams.marketDatumFloorL
                                  ? intFormat(String(marketDatumSearchParams.marketDatumFloorL))
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          建物階数
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              className="md:w-[300px]"
                              value={marketDatumSearchParams.marketDatumFloorsAboveGroundGteq || ''}
                              name="marketDatumSearchParams.marketDatumFloorsAboveGroundGteq"
                              id="q_floors_above_ground_gteq"
                              prefix="地上"
                              suffix="階"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorsAboveGroundGteq: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                  : ''
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorsAboveGroundGteq: value,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="q[floors_above_ground_gteq]"
                              id="floors_above_ground_gteq"
                              value={
                                marketDatumSearchParams.marketDatumFloorsAboveGroundGteq
                                  ? intFormat(
                                      String(
                                        marketDatumSearchParams.marketDatumFloorsAboveGroundGteq
                                      )
                                    )
                                  : ''
                              }
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              className="md:w-[300px]"
                              value={marketDatumSearchParams.marketDatumFloorsAboveGroundLteq || ''}
                              name="marketDatumSearchParams.marketDatumFloorsAboveGroundLteq"
                              id="floors_above_ground_lteq"
                              prefix="地上"
                              suffix="階"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorsAboveGroundLteq: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                  : ''
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorsAboveGroundLteq: value,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="q[floors_above_ground_lteq]"
                              id="q_floors_above_ground_lteq"
                              value={
                                marketDatumSearchParams.marketDatumFloorsAboveGroundLteq
                                  ? intFormat(
                                      String(
                                        marketDatumSearchParams.marketDatumFloorsAboveGroundLteq
                                      )
                                    )
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          総戸数
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              className="md:w-[300px]"
                              value={
                                marketDatumSearchParams.marketDatumFloorsNumberOfUnitsGteq || ''
                              }
                              name="marketDatumSearchParams.marketDatumFloorsNumberOfUnitsGteq"
                              id="q_number_of_units_gteq"
                              suffix="戸"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorsNumberOfUnitsGteq: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                  : ''
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorsNumberOfUnitsGteq: value,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="q[number_of_units_gteq]"
                              id="floors_number_of_units_gteq"
                              value={
                                marketDatumSearchParams.marketDatumFloorsNumberOfUnitsGteq
                                  ? intFormat(
                                      String(
                                        marketDatumSearchParams.marketDatumFloorsNumberOfUnitsGteq
                                      )
                                    )
                                  : ''
                              }
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              className="md:w-[300px]"
                              value={
                                marketDatumSearchParams.marketDatumFloorsNumberOfUnitsLteq || ''
                              }
                              name="marketDatumSearchParams.marketDatumFloorsNumberOfUnitsLteq"
                              id="number_of_units_lteq"
                              suffix="戸"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorsNumberOfUnitsLteq: e.target.value,
                                })
                              }
                              onBlur={(e) => {
                                const value = e.target.value
                                  ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                  : ''
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumFloorsNumberOfUnitsLteq: value,
                                })
                              }}
                            />
                            <input
                              type="hidden"
                              name="q[number_of_units_lteq]"
                              id="q_number_of_units_lteq"
                              value={
                                marketDatumSearchParams.marketDatumFloorsNumberOfUnitsLteq
                                  ? intFormat(
                                      String(
                                        marketDatumSearchParams.marketDatumFloorsNumberOfUnitsLteq
                                      )
                                    )
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          構造
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <Select
                            multiple
                            className="text-sm bg-none h-[80px]"
                            name="q[building_structure_in][]"
                            id="q_building_structure_in"
                            options={buildingStructureOptionsForSearch}
                            value={marketDatumSearchParams.marketDatumBuildingStructureEq}
                            onChange={(e) => {
                              const values = Array.from(
                                e.target.selectedOptions,
                                (option) => option.value
                              )
                              setmarketDatumSearchParams({
                                ...marketDatumSearchParams,
                                marketDatumBuildingStructureEq: values,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          施工年月
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              style={{ width: '190px' }}
                              value={marketDatumSearchParams.marketDatumBuildDateGteq}
                              type="date"
                              name="q[build_date_gteq]"
                              id="q_build_date_gteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumBuildDateGteq: e.target.value,
                                })
                              }
                              maxCalendarYear={3}
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              style={{ width: '190px' }}
                              value={marketDatumSearchParams.marketDatumBuildDateLteq}
                              type="date"
                              name="q[build_date_lteq]"
                              id="q_build_date_lteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumBuildDateLteq: e.target.value,
                                })
                              }
                              maxCalendarYear={3}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          施工会社
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <Select
                            multiple
                            className="text-sm bg-none h-[80px]"
                            name="q[contractor_in][]"
                            id="q_contractor_in"
                            options={contractor_options}
                            value={marketDatumSearchParams.marketDatumContractorEq}
                            onChange={(e) => {
                              const values = Array.from(
                                e.target.selectedOptions,
                                (option) => option.value
                              )
                              setmarketDatumSearchParams({
                                ...marketDatumSearchParams,
                                marketDatumContractorEq: values,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          エレベーター
                        </div>
                        <div className="py-2 px-4 w-3/4">
                          <Select
                            className="text-sm"
                            name="q[elevator_in]"
                            id="q_elevator_in"
                            hasBlank
                            blankLabel=""
                            options={radioOptions}
                            value={marketDatumSearchParams.marketDatumElevatorEq}
                            onChange={(e) => {
                              setmarketDatumSearchParams({
                                ...marketDatumSearchParams,
                                marketDatumElevatorEq: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          オートロック
                        </div>
                        <div className="py-2 px-4 w-3/4">
                          <Select
                            className="text-sm"
                            name="q[auto_lock_in]"
                            id="q_auto_lock_in"
                            options={radioOptions}
                            value={marketDatumSearchParams.marketDatumAutoLockEq}
                            hasBlank
                            blankLabel=""
                            onChange={(e) => {
                              setmarketDatumSearchParams({
                                ...marketDatumSearchParams,
                                marketDatumAutoLockEq: e.target.value,
                              })
                            }}
                          />
                        </div>
                      </div>
                      {forRent && (
                        <div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              バルコニー
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                className="text-sm"
                                name="q[balcony_in]"
                                id="q_balcony_in"
                                options={radioOptions}
                                value={marketDatumSearchParams.marketDatumBalconyEq}
                                hasBlank
                                blankLabel=""
                                onChange={(e) => {
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    marketDatumBalconyEq: e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>

                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              インターネット無料
                            </div>
                            <div className="py-2 px-4 w-3/4">
                              <Select
                                className="text-sm"
                                name="q[free_internet_in]"
                                id="q_free_internet_in"
                                options={radioOptions}
                                value={marketDatumSearchParams.marketDatumFreeInternetEq}
                                hasBlank
                                blankLabel=""
                                onChange={(e) => {
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    marketDatumFreeInternetEq: e.target.value,
                                  })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          掲載年月日
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              style={{ width: '190px' }}
                              value={marketDatumSearchParams.marketDatumPublicationDateGteq}
                              type="date"
                              name="q[publication_date_gteq]"
                              id="q_publication_date_gteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumPublicationDateGteq: e.target.value,
                                })
                              }
                              maxCalendarYear={3}
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              style={{ width: '190px' }}
                              value={marketDatumSearchParams.marketDatumPublicationDateLteq}
                              type="date"
                              name="q[publication_date_lteq]"
                              id="q_publication_date_lteq_lteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumPublicationDateLteq: e.target.value,
                                })
                              }
                              maxCalendarYear={3}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                          成約年月日
                        </div>
                        <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                          <div className="flex items-center">
                            <Input
                              style={{ width: '190px' }}
                              value={marketDatumSearchParams.marketDatumContractDateGteq}
                              type="date"
                              name="q[contract_date_gteq]"
                              id="q_contract_date_gteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumContractDateGteq: e.target.value,
                                })
                              }
                              maxCalendarYear={3}
                            />
                            <span className="mx-2">〜</span>
                            <Input
                              style={{ width: '190px' }}
                              value={marketDatumSearchParams.marketDatumContractDateLteq}
                              type="date"
                              name="q[contract_date_lteq]"
                              id="q_contract_date_lteq"
                              onChange={(e) =>
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  marketDatumContractDateLteq: e.target.value,
                                })
                              }
                              maxCalendarYear={3}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {forSale && (
                      <div>
                        <div className="p-4">
                          <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                            道路
                          </div>
                          <div className="flex items-center">
                            <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                              接道
                            </div>
                            <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                              <Select
                                multiple
                                className="text-sm bg-none h-[80px]"
                                name="q[road_accesses_direction_in][]"
                                id="q_road_accesses_direction_in"
                                options={directionOptionsForSearch}
                                value={marketDatumSearchParams.roadAccessesDirectionEq}
                                onChange={(e) => {
                                  const values = Array.from(
                                    e.target.selectedOptions,
                                    (option) => option.value
                                  )
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    roadAccessesDirectionEq: values,
                                  })
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                            幅員
                          </div>
                          <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                            <div className="flex items-center">
                              <Input
                                className="md:w-[300px]"
                                value={marketDatumSearchParams.roadAccessesWidthGteq || ''}
                                name="marketDatumSearchParams.roadAccessesWidthGteq"
                                id="q_road_accesses_width_gteq"
                                suffix="m"
                                onChange={(e) =>
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    roadAccessesWidthGteq: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                    : ''
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    roadAccessesWidthGteq: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="q[road_accesses_width_gteq]"
                                id="q_road_accesses_width_gteq"
                                value={
                                  marketDatumSearchParams.roadAccessesWidthGteq
                                    ? intFormat(
                                        String(marketDatumSearchParams.roadAccessesWidthGteq)
                                      )
                                    : ''
                                }
                              />
                              <span className="mx-2">〜</span>
                              <Input
                                className="md:w-[300px]"
                                value={marketDatumSearchParams.roadAccessesWidthLteq || ''}
                                name="marketDatumSearchParams.roadAccessWidthLteq"
                                id="road_accesses_width_lteq"
                                suffix="m"
                                onChange={(e) =>
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    roadAccessesWidthLteq: e.target.value,
                                  })
                                }
                                onBlur={(e) => {
                                  const value = e.target.value
                                    ? cammedFormat(dataPrecisionSetup(e.target.value, 10, 0), 0)
                                    : ''
                                  setmarketDatumSearchParams({
                                    ...marketDatumSearchParams,
                                    roadAccessesWidthLteq: value,
                                  })
                                }}
                              />
                              <input
                                type="hidden"
                                name="q[road_accesses_width_lteq]"
                                id="q_road_accesses_width_lteq"
                                value={
                                  marketDatumSearchParams.roadAccessesWidthLteq
                                    ? intFormat(
                                        String(marketDatumSearchParams.roadAccessesWidthLteq)
                                      )
                                    : ''
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="py-5 px-4 text-right whitespace-normal text-black-base font-medium w-1/4 text-[11.8px] !leading-[1.25rem]">
                            道路種別
                          </div>
                          <div className="py-2 px-4 w-3/4 overflow-x-scroll">
                            <Select
                              multiple
                              className="text-sm bg-none h-[80px]"
                              name="q[road_accesses_building_standards_law_articles_in][]"
                              id="q_road_accesses_building_standards_law_articles_in"
                              options={buildingStandardsLawArticlesOptionsForSearch}
                              value={
                                marketDatumSearchParams.roadAccessesBuildingStandardsLawArticlesEq
                              }
                              onChange={(e) => {
                                const values = Array.from(
                                  e.target.selectedOptions,
                                  (option) => option.value
                                )
                                setmarketDatumSearchParams({
                                  ...marketDatumSearchParams,
                                  roadAccessesBuildingStandardsLawArticlesEq: values,
                                })
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="border-t border-gray-150"></div>
                    <div className="bg-white fixed bottom-0 flex w-full gap-2 px-6 py-3 justify-end">
                      <AnchorButton
                        className="text-primary-font border-white w-[120px]"
                        prefix={<RefreshIcon fontSize="small" />}
                        outline
                        size="small"
                        variant="primary"
                        href={resetpage()}
                      >
                        リセット
                      </AnchorButton>
                      <Button
                        className="text-white w-[120px]"
                        prefix={<SearchIcon fontSize="small" />}
                        size="small"
                        variant="primary"
                      >
                        検索
                      </Button>
                    </div>
                    <input value={page_type} type="hidden" name="data_type" />
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default SearchModal
