import * as React from 'react'
import { Table } from '@/components/Table'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import DeleteIcon from '@mui/icons-material/Delete'

type CustomizeTableProps = {
  use_view_name?: string
  purpose?: string
  display_keys?: string[]
  display_values?: string[]
  not_display_columns?: any[]
  dummy_tag?: string[]
  top_scrollbar?: boolean
  wrapper?: string
  container?: string
  sticky_flg?: boolean
  column_labels?: { [key: string]: string }
  head_sticky_styles?: { [key: number]: string }
  body_sticky_styles?: { [key: number]: string }
  setNotDisplayColumns?: any
  moveLeft?: any
  display_move_left_num?: number
  moveRight?: any
  display_move_right_num?: number
  custom_visibility_flg?: boolean
  custom_order_flg?: boolean
  display_visibility_num?: number
  not_display_text?: string
  setFilterNotDisplayColumns?: any
  td_height?: string
  height?:string
  thead_color?: string
  first_display_values?: string[]
  socond_display_values?: string[]
  first_select_name?: string[]
  second_select_name?: string[]
  onSelectBoxChange?: any
}

export const CustomizeTable: React.FC<CustomizeTableProps> = ({
  use_view_name,
  purpose = 'manual',
  display_keys = ['テストhead'],
  display_values = ['テストbody'],
  not_display_columns = [],
  dummy_tag = [],
  top_scrollbar = window.innerWidth > 768,
  wrapper = 'flex flex-wrap items-end justify-between',
  container = 'overflow-auto max-h-[calc(100vh-200px)] w-full',
  sticky_flg = true,
  column_labels = {},
  head_sticky_styles = {},
  body_sticky_styles = {},
  custom_visibility_flg = false,
  display_visibility_num = 1,
  setNotDisplayColumns,
  moveLeft,
  display_move_left_num = 2,
  moveRight,
  display_move_right_num = 1,
  not_display_text = '非表示',
  td_height = '',
  height = '',
  thead_color = 'bg-gray-150',
  first_display_values = ['テストbody1'],
  socond_display_values = ['テストbody2'],
  first_select_name = '(未設定)',
  second_select_name = '(未設定)',
  onSelectBoxChange,
}) => {
  // 基本ベース用(基本ベース用を拡張したい場合はチームメンバーに確認すること : manual以外の仮想DOMを作成することも視野に入れてください)
  if (purpose === 'manual') {
    return (
      <div className={wrapper}>
        <div className={container}>
          <Table topScrollbar={top_scrollbar}>
            <thead className={`${use_view_name === 'bussiness_type_layout' ? '' : 'bg-gray-150'}  text-sm sticky top-0`}>
              <tr className="whitespace-nowrap">
                {display_keys.map((column, index) => {
                  const isLastColumn = index === display_keys.length - 1

                  let head_sticky = ''
                  if (sticky_flg) {
                    head_sticky = head_sticky_styles[index] || ''
                  }

                  const columnLabel = column_labels[column] || column

                  return (
                    <th
                      key={index}
                      scope="col"
                      className={`py-2 px-8 font-medium relative text-center ${height} ${use_view_name === 'bussiness_type_layout' ? '' : 'bg-gray-150'} border-t border-b border-r border-l ${
                        use_view_name !== 'bussiness_type_layout' ? 'border-gray-300' : ''
                      } ${head_sticky} ${isLastColumn ? 'border-l' : ''} ${
                        not_display_columns[index] ? 'text-white bg-gray-500' : use_view_name === 'bussiness_type_layout' ? '' : 'bg-gray-150'
                      }`}
                    >
                      {columnLabel}
                      {index > display_visibility_num &&
                        (!custom_visibility_flg ? (
                          not_display_columns[index] ? (
                            <VisibilityOffIcon className="text-gray-200 mx-5" />
                          ) : (
                            <VisibilityIcon className="text-gray-600 mx-5" />
                          )
                        ) : not_display_columns[index] ? (
                          <VisibilityOffIcon
                            onClick={() => {
                              setNotDisplayColumns((prev) => {
                                const newState = [...prev]
                                newState[index] = !newState[index] // 目のIconをToggleで切替
                                return newState
                              })
                            }}
                            className="text-gray-200 mx-5"
                          />
                        ) : (
                          <VisibilityIcon
                            onClick={() => {
                              setNotDisplayColumns((prev) => {
                                const newState = [...prev]
                                newState[index] = !newState[index] // 目のIconをToggleで切替
                                return newState
                              })
                            }}
                            className="text-gray-600 mx-5"
                          />
                        ))}
                      {custom_visibility_flg && (
                        <>
                          {[
                            {
                              condition: index > display_move_left_num,
                              onClick: () => moveLeft(index),
                              symbol: String.fromCharCode(9664),
                            },
                            {
                              condition: index > display_move_right_num && !isLastColumn,
                              onClick: () => moveRight(index),
                              symbol: String.fromCharCode(9654),
                            },
                          ].map(
                            ({ condition, onClick, symbol }, i) =>
                              condition && (
                                <span
                                  key={i}
                                  className={`absolute ${
                                    i === 0 ? 'left-0' : 'right-0'
                                  } top-1/2 transform -translate-y-1/2 flex items-center justify-center w-5 h-5 rounded-full mx-1 cursor-pointer ${
                                    not_display_columns[index] ? 'text-gray-200' : 'text-gray-800'
                                  }`}
                                  onClick={onClick}
                                >
                                  {symbol}
                                </span>
                              )
                          )}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className={`whitespace-nowrap ${use_view_name === 'bussiness_type_layout' ? 'hidden' : ''}`}>
              <tr>
                {display_values.map((value, index) => {
                  let body_sticky = ''
                  if (sticky_flg) {
                    body_sticky = body_sticky_styles[index] || ''
                  }

                  return (
                    <td
                      key={index}
                      className={[
                        `text-sm text-center bg-white border-b border-r border-l ${
                          use_view_name !== 'bussiness_type_layout' ? 'border-gray-300' : ''
                        } min-w-[120px] px-2 ${td_height}`,
                        body_sticky,
                      ].join(' ')}
                    >
                      {value === '未設定' &&
                      (use_view_name === 'department_display_order_setting' ||
                        use_view_name === 'company_display_order_setting') &&
                      !not_display_columns[index] ? (
                        <select
                          className={[
                            'my-2 empty:hidden inline-flex text-sm ml-2 px-3 py-[4px] rounded-full border-[1px] border-[#EEE] border-transparent text-black',
                          ].join(' ')}
                        >
                          {dummy_tag.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      ) : not_display_columns[index] ? (
                        not_display_text
                      ) : (
                        value
                      )}
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    )
  }
  if (purpose === 'show_hide_toggle') {
    return (
      <div className={wrapper}>
        <div className={container}>
          <Table topScrollbar={top_scrollbar} max_width={`max-w-[15px]`}>
            <thead className="bg-gray-150 text-sm sticky top-0">
              <tr className="whitespace-nowrap">
                {display_keys.map((column, index) => {
                  const isLastColumn = index === display_keys.length - 1

                  let head_sticky = ''
                  if (sticky_flg) {
                    head_sticky = head_sticky_styles[index] || ''
                  }

                  const columnLabel = column_labels[column] || column
                  const filteredColumns = display_keys.filter(
                    (_, index) => index < display_visibility_num || !not_display_columns[index]
                  )
                  const notArrowFirstDisplayElement = filteredColumns[display_visibility_num + 1]
                  const notArrowLastDisplayElement = filteredColumns[filteredColumns.length - 1]
                  return (
                    <th
                      key={index}
                      scope="col"
                      className={`py-2 px-8 font-medium relative text-center bg-gray-150 border-t border-b border-r border-l border-gray-300 ${head_sticky} ${
                        isLastColumn ? 'border-l' : ''
                      } ${not_display_columns[index] ? 'hidden' : 'bg-gray-150'}`}
                    >
                      {columnLabel}
                      {index > display_visibility_num &&
                        (!custom_visibility_flg ? (
                          !not_display_columns[index] ? (
                            <DeleteIcon
                              className="text-gray-600 mx-5 cursor-pointer"
                              style={{ width: '17px', height: '20px' }}
                            />
                          ) : null
                        ) : !not_display_columns[index] ? (
                          <DeleteIcon
                            onClick={() => {
                              setNotDisplayColumns((prev) => {
                                const newState = [...prev]
                                newState[index] = !newState[index]
                                return newState
                              })
                            }}
                            className="text-gray-600 mx-5 cursor-pointer"
                            style={{ width: '17px', height: '20px' }}
                          />
                        ) : null)}
                      {custom_visibility_flg && (
                        <>
                          {[
                            {
                              condition:
                                index > display_move_left_num &&
                                columnLabel !== notArrowFirstDisplayElement &&
                                not_display_columns.filter((item) => item === false).length !==
                                  display_move_left_num + 1,
                              onClick: () => moveLeft(index),
                              symbol: String.fromCharCode(9664),
                            },
                            {
                              condition:
                                index > display_move_right_num &&
                                columnLabel !== notArrowLastDisplayElement,
                              onClick: () => moveRight(index),
                              symbol: String.fromCharCode(9654),
                            },
                          ].map(({ condition, onClick, symbol }, i) => {
                            return (
                              condition && (
                                <span
                                  key={i}
                                  className={`absolute ${
                                    i === 0 ? 'left-0' : 'right-0'
                                  } top-1/2 transform -translate-y-1/2 flex items-center justify-center w-5 h-5 rounded-full mx-1 cursor-pointer ${
                                    not_display_columns[index] ? 'text-gray-200' : 'text-gray-800'
                                  }`}
                                  onClick={onClick}
                                >
                                  {symbol}
                                </span>
                              )
                            )
                          })}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className="whitespace-nowrap">
              <tr>
                {display_values.map((value, index) => {
                  let body_sticky = ''
                  if (sticky_flg) {
                    body_sticky = body_sticky_styles[index] || ''
                  }

                  return (
                    <td
                      key={index}
                      className={[
                        'text-sm text-center bg-white border-b border-r border-l border-gray-300 min-w-[120px] px-2',
                        not_display_columns[index] ? 'hidden' : '',
                        body_sticky,
                      ].join(' ')}
                    >
                      {value}
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    )
  }
  if (purpose === 'dubble_table') {
    return (
      <div className="overflow-x-auto">
        <div className="flex item-center mb-1]">
          {first_display_values.map((_, index) => (
            <div key={index} className="flex items-center h-[25px] w-[100%]">
              <div
                className={`${
                  index === 0 ? 'hidden' : 'py-1 border border-[#3885b0] bg-white text-[#3885b0] mt-[11px] h-[35px] rounded-tl-sm rounded-bl-sm border-r-0 pt-[5px] ml-[0.2%]'
                }`}
                style={{ flex: '1', cursor: 'pointer' }}
                onClick={() => moveLeft(index)}
              >
                {String.fromCharCode(9664)}
              </div>
              <p
                className={`${
                  index === 0
                    ? 'w-[90%] rounded-tl-sm rounded-bl-sm border-r-0 ml-[0.25%] pl-[10%]'
                    : index === first_display_values.length - 1
                    ? 'w-[90%] rounded-tr-sm rounded-br-sm border-l-0 mr-[0.5%] mr-[1.4%] pr-[14%]'
                    : 'w-[80%] border-l-0 border-r-0'
                } p-[7px] text-center border border-[#3885b0] bg-white text-[#3885b0] mt-[11px] text-sm h-[35px]`}
              >
                移動
              </p>
              <div
                className={`${
                  index === first_display_values.length - 1
                    ? 'hidden'
                    : 'py-1 border border-[#3885b0] bg-white text-[#3885b0] mt-[11px] h-[35px] rounded-tr-sm rounded-br-sm border-l-0 pt-[5px] mr-[1.3%] pl-[5%]'
                }`}
                style={{ flex: '1', cursor: 'pointer' }}
                onClick={() => moveRight(index)}
              >
                {String.fromCharCode(9654)}
              </div>
            </div>
          ))}
        </div>

        <div className={wrapper}>
          <div className={container}>
            <Table topScrollbar={top_scrollbar} max_width={`max-w-[15px]`} overflow_x_auto={``}>
              <thead className="text-sm sticky top-0 h-[38px]">
                <tr className="whitespace-nowrap">
                  {first_display_values.map((_, index) => (
                    <th key={index}>
                      <div className="flex items-center justify-center" style={{ width: '100%' }}>
                        <select
                          id={`select-${index}`}
                          className={`border rounded px-2 py-1 text-center h-[35px] font-normal mt-[16px] border-gray-border mr-[2px]`}
                          style={{ flex: '8', cursor: 'pointer', width: 'max-content' }}
                          value={first_select_name[index]}
                          onChange={(e) => {
                            onSelectBoxChange(index, e.target.value)
                          }}
                        >
                          {display_keys.map((column, keyIndex) => (
                            <option key={keyIndex} value={column}>
                              {column}
                            </option>
                          ))}
                        </select>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>

              <thead className="text-sm sticky top-0">
                <tr className="whitespace-nowrap">
                  {socond_display_values.map((_, index) => (
                    <th
                      key={index}
                      className={`${
                        index === 0
                          ? 'text-left'
                          : index === socond_display_values.length - 1
                          ? 'text-right'
                          : 'text-center'
                      }`}
                    >
                      <div className="flex items-center justify-center" style={{ width: '100%' }}>
                        <select
                          id={`select-${index}`}
                          className={`border rounded px-2 py-1 text-center h-[35px] font-normal mt-[5px] border-gray-border mr-[2px]`}
                          style={{ flex: '8', cursor: 'pointer', width: 'max-content' }}
                          value={second_select_name[index]}
                          onChange={(e) => {
                            onSelectBoxChange(index + second_select_name.length, e.target.value)
                          }}
                        >
                          {display_keys.map((column, keyIndex) => (
                            <option key={keyIndex} value={column}>
                              {column}
                            </option>
                          ))}
                        </select>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>

            </Table>
          </div>
        </div>
      </div>
    )
  }
}
