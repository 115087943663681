import { Breadcrumb } from '@/components/Breadcrumb'
import { AnchorButton, Button } from '@/components/Button'
import { FileUpload } from '@/components/FileUpload'
import { MapHandles } from '@/components/GoogleMapViewer'
import { GoogleMapViewer } from '@/components/GoogleMapViewer/PropertyPublicFiles'
import { Input } from '@/components/Input'
import Base from '@/components/Layout/Base'
import { RadioButton } from '@/components/RadioButton'
import { Select } from '@/components/Select'
import SelectAllTransferList from '@/components/SelectAllTransferList'
import { DetailCard } from '@/components/SortableList/PropertyDocumentFormat/DetailCard'
import { Row, Table, Td, Th } from '@/components/Table'
import { Textarea } from '@/components/Textarea'
import { buildingStructureType } from '@/config/langs/building_structure'
import { LatDefault, LngDefault } from '@/models/property'
import type { File } from '@/types/file'
import type { LatLng, Property } from '@/types/property'
import type { PropertyDocumentFormat } from '@/types/propertyDocumentFormat'
import type { PropertyDocumentFormatDetail } from '@/types/propertyDocumentFormatDetail'
import type { PublicFileCorrection } from '@/types/publicFileCorrection'
import type { User } from '@/types/user'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import { useJsApiLoader } from '@react-google-maps/api'
import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const defaultFormatDetailData = {
  column_name_1: '',
  column_name_2: '',
  element_name: '',
}

type PublicFileDetail = {
  id: number
  hashid: string
  public_status: boolean
  password: string
  url: string
  note: string
  attached_file: File
}

type Props = {
  current_user: User
  property: Property
  corrections?: PublicFileCorrection[]
  column_list: { value: string; text: string }[]
  position_column_list: { value: string; text: string }[]
  cammed_format_conversions: string[]
  file_detail: PublicFileDetail
  property_files: File[]
  selected_property_files: File[]
  property_document_format: PropertyDocumentFormat
  google_maps_api_key: string
  polygons_api_base_url: string
  authenticity_token: string
  error_messages: string[]
}

const PropertyPublicFilesDetailPage: React.FC<Props> = ({
  current_user,
  property,
  corrections,
  column_list,
  position_column_list,
  cammed_format_conversions,
  file_detail,
  property_files = [],
  selected_property_files = [],
  property_document_format,
  google_maps_api_key,
  polygons_api_base_url,
  authenticity_token,
  error_messages,
}) => {
  const isSaved = () => {
    return file_detail.id !== null
  }
  const title = isSaved() ? '変更を保存' : '登録'
  const [formatDetailData, setFormatDetailData] = React.useState<PropertyDocumentFormatDetail[]>(
    corrections?.length > 0
      ? corrections
      : property_document_format?.property_document_format_details?.length > 0
      ? property_document_format.property_document_format_details
      : [defaultFormatDetailData]
  )
  const [fileDetailData, setFileDetailData] = React.useState<PublicFileDetail>({
    url: property_document_format?.name || '新規作成',
  })
  const handleFormatDetailData = (value, index, column) => {
    const updatedData = [...formatDetailData]
    updatedData[index] = {
      ...updatedData[index],
      [column]: value,
    }
    setFormatDetailData(updatedData)
  }
  const handleDeleteFormatDetailData = (e, index) => {
    e.preventDefault()
    const newFormatDetailData = [...formatDetailData]
    newFormatDetailData.splice(index, 1)
    setFormatDetailData(newFormatDetailData)
  }
  const onChangeFormatDetailData = (e, index, column) => {
    const format_details = [...formatDetailData]
    format_details.splice(index, 1, {
      ...format_details[index],
      property_document_correction: { [column]: e.target.value },
    })
    setFormatDetailData(format_details)
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: ['geometry', 'drawing', 'places'],
  })

  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  const mapRef = React.useRef<MapHandles>(null)
  const latLng: LatLng = {
    lat: property?.address_geocoding[0] || LatDefault,
    lng: property?.address_geocoding[1] || LngDefault,
  }
  const dataLatLng: LatLng = {
    lat: property?.address_geocoding[0],
    lng: property?.address_geocoding[1],
  }
  const propertyShape = property?.shape?.latlngs.map((latlng) => ({
    lat: latlng.lat,
    lng: latlng.lng,
  }))
  const [onLoadComplete, setOnLoadComplete] = React.useState<boolean>(false)

  const [youtoInfo, setYoutoInfo] = React.useState<any>({})
  const [file, setFile] = React.useState(null)
  const [deleteExistingFile, setDeleteExistingFile] = React.useState(null)
  const [inputKey, setInputKey] = React.useState(Date.now())
  const [previewImagePath, setPreviewImagePath] = React.useState<string>()

  React.useEffect(() => {
    setFileDetailData(file_detail)
  }, [file_detail])

  const getInputProps = {
    'image/jpeg': [],
    'image/png': [],
    'image/gif': [],
  }

  const handleFileChange = (fileObj) => {
    setFile(fileObj)
    setFileDetailData({
      ...fileDetailData,
      attached_file: fileObj,
    })

    const dataUrl = URL.createObjectURL(fileObj)
    setPreviewImagePath(dataUrl)
  }

  const handleDeleteExistingFile = () => {
    setFileDetailData({
      ...fileDetailData,
      attached_file: null,
    })
    setFile(null)
    if (file_detail.attached_file) {
      setDeleteExistingFile(true)
    }
    setInputKey(Date.now())
  }

  const moveCard = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setFormatDetailData((prevCards: PropertyDocumentFormatDetail[]) => {
        const dragCard = prevCards[dragIndex]
        const newCards = [...prevCards]
        newCards.splice(dragIndex, 1)
        newCards.splice(hoverIndex, 0, dragCard)
        return newCards
      })
    },
    [setFormatDetailData]
  )

  const replace_element_name = (converted_column, format_detail) => {
    if (!format_detail.correction_text || !format_detail.position) {
      return converted_column
    }
    switch (format_detail.position) {
      case '1':
        return `${format_detail.correction_text} ${converted_column}`
      case '2':
        return `${converted_column} ${format_detail.correction_text}`
      case '3':
        return format_detail.correction_text
    }
  }

  const convert_display = (format_detail) => {
    let converted_column = /^proposal\./.test(format_detail.element_name)
      ? convert_proposal_column(format_detail.element_name.replace(/^proposal\./, ''))
      : convert_property_column(format_detail.element_name)

    return replace_element_name(converted_column, format_detail)
  }

  const convert_property_column = (column) => {
    if (!column) {
      return ''
    }
    const columns = column.split('|')

    if (cammed_format_conversions.includes(columns[0])) {
      return conversion_to_cammed_format(property, columns[0], columns[1])
    }
    return convert_column_value(columns[0])
  }

  const convert_column_value = (column) => {
    switch (column) {
      case 'user':
        return property.user?.name
      case 'address_location':
        return `${property.prefecture}${property.city}${property.town}${property.chome}`
      case 'address_chiban':
        return `${property.prefecture}${property.city}${property.town}${property.chiban}`
      case 'business_type':
        return property.business_type?.name
      case 'transportation':
        return property.transportations_ordered.map(
          (transportation) => transportation.transportation_display
        )
      case 'road_access':
        return property.road_accesses.map((access) => access.road_access_display)
      case 'youto_chiiki_hand':
        const youto_chiiki_hand_text = property.area_info_hands.map(
          (youtochiikihand) => youtochiikihand.youto_chiiki_hand
        )
        return youto_chiiki_hand_text.filter((text) => text).join(' / ')
      case 'building_coverage_ratio_hand':
        const building_coverage_ratio_hand_text = property.area_info_hands.map(
          (buildingcoverageratiohand) => {
            const value = buildingcoverageratiohand.building_coverage_ratio_hand
            return value ? `${value}%` : ''
          }
        )
        return building_coverage_ratio_hand_text.filter((text) => text).join(' / ')
      case 'floor_area_ratio_hand':
        const floor_area_ratio_hand_text = property.area_info_hands.map((floorarearatiohand) => {
          const value = floorarearatiohand.floor_area_ratio_hand
          return value ? `${value}%` : ''
        })
        return floor_area_ratio_hand_text.filter((text) => text).join(' / ')
      case 'memo_updated_date':
      case 'selled_at':
      case 'build_completed_at':
      case 'purchace_applied_at':
      case 'purchace_contract_at':
      case 'purchace_settlement_at':
        return property[column] ? format(parseISO(property[column]), 'yyyy年MM月dd日') : ''
      case 'building_structure':
        return property[column] ? buildingStructureType[property[column]] : ''
      case 'area_info_0':
        return property.area_info
          ? property.area_info_detail_0
          : property.instant_city_planning_detail_0
      case 'area_info_1':
        return property.area_info
          ? property.area_info_detail_1
          : property.instant_city_planning_detail_1
      case 'area_info_2':
        return property.area_info
          ? property.area_info_detail_2
          : property.instant_city_planning_detail_2
      case 'area_info_3':
        return property.area_info
          ? property.area_info_detail_3
          : property.instant_city_planning_detail_3
      default:
        return property[column]
    }
  }

  const convert_text_value = (text: string | number): string => {
    if (typeof text === 'string') {
      return text.replace(/\n/g, '/')
    }
    return String(text).replace(/\n/g, '/')
  }

  const convert_proposal_column = (column) => {
    if (!property.ongoing_proposal) {
      return ''
    }
    const columns = column.split('|')
    if (cammed_format_conversions.includes(columns[0])) {
      return conversion_to_cammed_format(property.ongoing_proposal, columns[0], columns[1])
    }

    switch (columns[0]) {
      case 'source':
        return blind_source(property.ongoing_proposal.source)
      case 'source_infos':
        return blind_source(property.ongoing_proposal.source_infos)
      case 'source_user':
        return property.ongoing_proposal.source_user?.name
      case 'recepted_date':
        return property.ongoing_proposal[column]
          ? format(parseISO(property.ongoing_proposal[column]), 'yyyy年MM月dd日')
          : ''
      default:
        return property.ongoing_proposal[column]
    }
  }

  const cammed_format = (value, exp, unit) => {
    if (!value) {
      return ''
    }
    let values = `${value}`.split('.')
    if (!exp) {
      exp = 0
    }
    if (values.length > 1 && parseInt(values[1]) == 0) {
      values = [values[0]]
    }
    values[0] = parseInt(values[0]).toLocaleString()
    if (exp != 0) {
      if (values.length < 2) {
        values[1] = '0'
      }
      if (values[1].length <= exp) {
        values[1] += '0' * (exp - values[1].length)
      } else {
        values[1] = values[1][(0).exp - 1]
      }
    }
    if (unit == '(坪)') {
      return `( ${values.join('.')}坪 )`
    }

    return values.join('.') + String(unit)
  }


  const adjustValue = (value, exp) => {
    if (exp === null || exp == undefined) {
      return value
    }
    return value ? value / (10 ** exp) : ''
  }
  const conversion_to_cammed_format = (obj, column, exp) => {
    const unitMap = {
      0: '円',
      3: '千円',
      4: '万円',
      6: '百万円',
    }

    const isPercentage = [
      'business_income_yield',
      'coverage_ratio',
      'current_prospective_noi_yield',
      'current_prospective_yield',
      'prospective_yield',
      'prospective_noi_yield',
      'volume_rate',
    ].includes(column)

    const isSquareMeters = [
      'area_m3',
      'effective_area',
      'occupied_area',
      'total_floor_area',
    ].includes(column)

    const isTsubo = [
      'area_tsubo',
      'effective_area_tsubo',
      'occupied_area_tsubo',
      'total_floor_area_tsubo',
    ].includes(column)

    const precision = isPercentage || isSquareMeters || isTsubo ? 2 : 0
    const unit = isPercentage
      ? '%'
      : isSquareMeters
      ? '㎡'
      : isTsubo
      ? '坪'
      : unitMap[exp] || ''
    const value = obj[column] || ''

    return cammed_format(adjustValue(value, exp), precision, unit)
  }

  const blind_source = (data) => {
    return data
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', '公開ページ', title]}
          property_id={property.hashid}
          tab="public_files"
        />
      }
      submenuCategory="properties"
    >
      <form
        className="mt-8"
        id="property_public_file_form"
        action={
          isSaved() ? `/property_public_files/${file_detail.hashid}` : '/property_public_files'
        }
        acceptCharset="UTF-8"
        encType="multipart/form-data"
        method="post"
      >
        <input type="hidden" name="_method" value={isSaved() ? 'patch' : 'post'} />
        <input type="hidden" name="property_id" value={property.id} />
        <input
          type="hidden"
          name="property_public_file[property_document_format_id]"
          value={property_document_format?.id}
        />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <div>
          <div>
            参照物件概要書フォーマット名：
            {property_document_format ? property_document_format.name : '新規作成'}{' '}
          </div>
          <div className="py-6">
            <div className="flex flex-col gap-1">
              <div className="flex gap-[6px]">
                <div className="w-80" />
                <div className="w-full">項目1</div>
                <div className="w-full">項目2</div>
                <div className="w-full">カラム選択</div>
                <div className="w-36" />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <DndProvider backend={HTML5Backend}>
                {formatDetailData.map((data, index) => (
                  <DetailCard
                    key={index}
                    index={index}
                    id={data.id}
                    column_name_1={data.column_name_1}
                    column_name_2={data.column_name_2}
                    element_name={data.element_name}
                    column_list={column_list}
                    formatDetailData={formatDetailData}
                    onChangeFormatDetailData={handleFormatDetailData}
                    onClickDeleteFormatDetailData={handleDeleteFormatDetailData}
                    moveCard={moveCard}
                  />
                ))}
              </DndProvider>
            </div>
            <div className="flex gap-2 mt-2">
              <Button
                outline
                size="small"
                onClick={(e) => {
                  e.preventDefault()
                  setFormatDetailData([...formatDetailData, defaultFormatDetailData])
                }}
              >
                追加
              </Button>
            </div>
          </div>
          <div className="py-6 mb-2">
            <Table className="border-t border-b block mb-4">
              <tbody className="block md:table-row-group">
                {formatDetailData.map((format_detail, i) => (
                  <tr className="flex mr-2 my-2" key={i}>
                    {format_detail.colspan ? (
                      <td colSpan="{format_detail.colspan}" className="w-2/5 mr-2 py-3">
                        {format_detail.column_name_1}
                      </td>
                    ) : (
                      <>
                        <td className="w-1/5 mr-2 py-3">{format_detail.column_name_1}</td>
                        <td className="w-1/5 mr-2 py-3">{format_detail.column_name_2}</td>
                      </>
                    )}
                    <td className="w-1/5 mr-2 py-3">
                      {(() => {
                        const column = convert_display(format_detail)
                        return Array.isArray(column)
                          ? column.map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))
                          : column
                      })()}
                    </td>
                    <td className="w-1/5 mr-2">
                      <Input
                        value={format_detail.correction_text}
                        name={`format_detail[${i}][correction_text]`}
                        id={`format_detail_${i}_correction_text`}
                        className="w-full"
                        placeholder="(補正テキスト)"
                        onChange={(e) =>
                          handleFormatDetailData(e.target.value, i, 'correction_text')
                        }
                      />
                    </td>
                    <td className="w-1/5">
                      <Select
                        value={format_detail.position}
                        name={`format_detail[${i}][position]`}
                        id={`format_detail_${i}_correction_position`}
                        className="text-sm"
                        options={position_column_list}
                        onChange={(e) => handleFormatDetailData(e.target.value, i, 'position')}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Table className="border-t border-b block mb-4">
              <tbody className="block md:table-row-group">
                <Row>
                  <Th>公開</Th>
                  <Td>
                    <div className="flex gap-8">
                      <RadioButton
                        key="on"
                        name="property_public_file[public_status]"
                        id={`property_public_file_on`}
                        text="有"
                        value="enabled"
                        checked={fileDetailData?.public_status == 'enabled'}
                        onChange={(e) => {
                          setFileDetailData({
                            ...fileDetailData,
                            public_status: 'enabled',
                          })
                        }}
                      />
                      <RadioButton
                        key="off"
                        name="property_public_file[public_status]"
                        id={`property_public_file_off`}
                        text="無"
                        value="disabled"
                        checked={fileDetailData?.public_status == 'disabled'}
                        onChange={(e) => {
                          setFileDetailData({
                            ...fileDetailData,
                            public_status: 'disabled',
                          })
                        }}
                      />
                    </div>
                  </Td>
                </Row>
                <Row>
                  <Th>閲覧パスワード</Th>
                  <Td>
                    <Input
                      value={fileDetailData?.password}
                      name={`property_public_file[password]`}
                      id="property_public_file_password"
                      type="text"
                      className="w-full"
                      onChange={(e) => {
                        setFileDetailData({
                          ...fileDetailData,
                          password: e.target.value,
                        })
                      }}
                    />
                  </Td>
                </Row>
                <Row>
                  <Th>閲覧画面URL</Th>
                  <Td>
                    <p>
                      {location.origin}/property_public_files/
                      {isSaved() ? file_detail.hashid : 'xxxx'}/page_view?url=◯◯◯ のようになります
                    </p>
                    <Input
                      value={fileDetailData?.url}
                      name={`property_public_file[url]`}
                      id="property_public_file_url"
                      className="w-full"
                      onChange={(e) => {
                        setFileDetailData({
                          ...fileDetailData,
                          url: e.target.value,
                        })
                      }}
                    />
                  </Td>
                </Row>
                <Row>
                  <Th>メモ</Th>
                  <Td>
                    <Textarea
                      value={fileDetailData?.note}
                      name={`property_public_file[note]`}
                      id="property_public_file_note"
                      className="w-full"
                      onChange={(e) => {
                        setFileDetailData({
                          ...fileDetailData,
                          note: e.target.value,
                        })
                      }}
                    />
                  </Td>
                </Row>
                <Row>
                  <Th>物件写真</Th>
                  <Td>
                    <p className="text-sm mb-4 mt-6">
                      ※JPEG, PNG, GIFのファイル形式のみ対応しています。
                      (添付のファイルサイズは500.0MB以内でお願いします)
                    </p>
                    <FileUpload
                      key={inputKey}
                      className="mt-4 mb-4 w-full"
                      name="property_public_file[attached_file]"
                      accept={getInputProps}
                      onDrop={(file: File) => handleFileChange(file)}
                      onChange={(e) => handleFileChange(e.target.files[0])}
                    />
                    <div className="flex justify-between w-full items-center py-4">
                      <div className="flex">
                        {fileDetailData?.attached_file ? (
                          <div className="flex items-center">
                            <div className="ml-2 text-sm text-primary-font">
                              <a
                                href={previewImagePath ?? fileDetailData.attached_file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {fileDetailData.attached_file.name}
                              </a>
                            </div>
                            <div className="flex items-center">
                              <button
                                type="button"
                                onClick={handleDeleteExistingFile}
                                className="px-2 py-[1px] text-red-700"
                              >
                                <DeleteOutlined style={{ fontSize: '1rem' }} />
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            {file && (
                              <div className="flex items-center">
                                <button
                                  type="button"
                                  onClick={handleDeleteExistingFile}
                                  className="px-2 py-[1px] text-red-700"
                                >
                                  <DeleteOutlined style={{ fontSize: '1rem' }} />
                                </button>
                              </div>
                            )}
                          </>
                        )}
                        <input
                          type="hidden"
                          name="property_public_file[delete_file]"
                          value={deleteExistingFile}
                        />
                      </div>
                    </div>
                  </Td>
                </Row>
                <Row>
                  <Th>添付ファイル</Th>
                  <Td>
                    <div className="py-6">
                      <SelectAllTransferList
                        attachments={property_files}
                        selected_attachments={selected_property_files}
                      />
                    </div>
                  </Td>
                </Row>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="mb-4">
          {isLoaded && (
            <GoogleMapViewer
              csrfToken={csrfToken.content}
              property_id={Number(property?.id)}
              lat={latLng.lat}
              lng={latLng.lng}
              propertyLat={dataLatLng.lat}
              propertyLng={dataLatLng.lng}
              propertyShape={propertyShape}
              ref={mapRef}
            />
          )}
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 pt-4 mb-3">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href={
              isSaved()
                ? `/properties/${property.hashid}?tab=public_files`
                : `/property_public_files/select_formats?property_id=${property.hashid}`
            }
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">{title}</Button>
        </div>
      </form>
    </Base>
  )
}

export default PropertyPublicFilesDetailPage
