import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Table } from '@/components/Table'
import type { User } from '@/types/user'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { Breadcrumb } from '@/components/Breadcrumb'
import { RadioButton } from '@/components/RadioButton'
import { Textarea } from '@/components/Textarea'
import { format, parseISO } from 'date-fns'

import { proposalStatusOptions } from '@/models/proposal'
import type { Proposal } from '@/types/proposal'

import { cammedFormat, intFormat } from '@/utils/cammedFormat'
import { DropDown, Option } from '@/components/DropDown/Index'

type Props = {
  status: string
  current_user: User
  users: User[]
  authenticity_token: string
  proposal: Proposal
  error_messages: string[]
  already_starting_message: string
  sources_list?: string[]
  sources_branch_list?: string[]
  sources_staff_list?: string[]
  is_source_required: boolean
}

const ProposalsEditPage: React.FC<Props> = ({
  status,
  current_user,
  users,
  authenticity_token,
  proposal,
  error_messages,
  already_starting_message,
  sources_list,
  sources_branch_list,
  sources_staff_list,
  is_source_required,
}) => {
  const [proposalData, setProposalData] = React.useState<Proposal>()
  const [currentSourceUser, setCurrentSourceUser] = React.useState<Option | null>(null)
  const [currentProposalUser, setCurrentProposalUser] = React.useState<Option | null>(null)
  const [usersOptions, setUsersOptions] = React.useState<Option[]>()

  // data_precision_setup_func
  const dataPrecisionSetup = (v: string, percision, scale) => {
    let value: string | number = v
    let match = value.match(/[0-9\-\.０-９ーー―‐ー−．．]/g)
    value = match === null ? '' : match.join('')
    value = value.replace(/[０-９ーー―‐ー−．．]/g, function (c) {
      return c.match(/[ーー―‐ー−]/)
        ? '-'
        : c.match(/[．．]/)
        ? '.'
        : String.fromCharCode(c.charCodeAt(0) - 0xfee0)
    })
    value = value.replace(/,/g, '')
    match = value.match(/^[+0]*(([0-9]+)(\.)([0-9]*)|([0-9]*))$/)
    if (match) {
      if (typeof match[2] == 'string') {
        value = match[2].substr(0, percision - scale) + match[3] + match[4].substr(0, scale)
      } else if (typeof match[5] == 'string') {
        value = match[5].substr(0, percision - scale)
      }
    } else {
      value = value.replace(/^[+0]*([0-9]+(\.[0-9]+))?.*$/, '$1')
    }
    return intFormat(value || '0')
  }

  const suggestedUnitPriceChanged = (proposalData: Proposal) => {
    const property_type = proposalData?.property.property_type
    const effective_area_tsubo = Number(proposalData?.property.effective_area_tsubo)
    const occupied_area_tsubo = Number(proposalData?.property.occupied_area_tsubo)
    let suggested_unit_price
    if (property_type === '土地') {
      suggested_unit_price =
        effective_area_tsubo === 0
          ? ''
          : Math.floor(
              intFormat(String(proposalData?.suggested_total_price)) / effective_area_tsubo
            )
    } else {
      suggested_unit_price =
        occupied_area_tsubo === 0
          ? ''
          : Math.floor(intFormat(String(proposalData?.suggested_total_price)) / occupied_area_tsubo)
    }

    const data = {
      suggested_total_price: cammedFormat(
        intFormat(String(proposalData?.suggested_total_price)),
        0
      ),
      suggested_unit_price: cammedFormat(suggested_unit_price, 0),
    }

    return data
  }

  React.useEffect(() => {
    const receptedAtDefault = proposal.recepted_at
      ? parseISO(proposal.recepted_at)
      : parseISO(new Date().toISOString())

    if (!proposal.recepted_at) {
      receptedAtDefault.setSeconds(0)
    }
    const recepted_at = format(receptedAtDefault, 'yyyy-MM-dd HH:mm:ss')

    setProposalData({
      ...proposal,
      name: proposal?.property.name ?? '',
      user_id: proposal?.property.user_id ?? '',
      department_name: proposal?.property?.department_name ?? '',
      ongoing_proposal_id: proposal?.property.ongoing_proposal_id,
      recepted_at,
      ongoing:
        status ?? (proposal.id === proposal?.property.ongoing_proposal_id ? 'ongoing' : 'yet'),
    })

    setUsersOptions([
      ...users.map((user) => ({
        value: Number(user.id),
        label: (user.name ?? '') + ' / ' + (user.department?.name ?? '（なし）'),
      })),
    ])

    console.log(proposal)
  }, [])

  React.useEffect(() => {
    proposalData?.source_user_id
      ? setCurrentSourceUser({
          value: proposalData.source_user_id,
          label: usersOptions.find((element) => element.value === proposalData.source_user_id)
            ?.label,
        })
      : setCurrentSourceUser(null)

    proposalData?.ongoing === 'ongoing' && proposalData?.user_id
      ? setCurrentProposalUser({
          value: proposalData.user_id,
          label: usersOptions.find((element) => element.value === proposalData.user_id)?.label,
        })
      : setCurrentProposalUser(null)
  }, [proposalData])

  const title = proposal?.hashid ? '入手情報編集' : '入手情報登録'
  const submit = proposal?.hashid ? '編集を保存' : '登録'
  const param = window.location.search
  const property_id = new URLSearchParams(param).get('property_id')

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', '入手情報', title]}
          property_id={property_id}
          tab="proposals"
        />
      }
      city={proposal.property.prefecture + proposal.property.city}
      submenuCategory="properties"
    >
      <form
        className="mt-8"
        action={proposal?.hashid ? `/proposals/${proposal?.hashid}` : '/proposals'}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={proposal?.hashid ? 'patch' : 'post'} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        {error_messages.length > 0 && (
          <ul className="mb-6 text-sm text-red">
            {error_messages.map((message, i) => (
              <li key={i} className="mb-2 break-all">
                {message}
              </li>
            ))}
          </ul>
        )}
        <Table className="block h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <tbody className="block md:table-row-group">
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                所在地
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                住所表示：{proposal.property.prefecture} {proposal.property.city}{' '}
                {proposal.property.town} {proposal.property.chome}　地番：{proposal.property.chiban}
                <input
                  type="hidden"
                  name="proposal[property_id]"
                  id="proposal_property_id"
                  value={proposalData?.property.id}
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                ステータス
                <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
                  必須
                </span>
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                {proposal['enable_edit_ongoing_state?'] ? (
                  <>
                    <div className="flex gap-4 flex-col md:flex-row">
                      {proposalStatusOptions.map((item, i) => (
                        <RadioButton
                          key={i}
                          name="proposal[status]"
                          id={`ongoing_state_${i}`}
                          text={item.text}
                          value={item.value}
                          checked={item.value === proposalData?.ongoing}
                          onChange={(e) => {
                            const ongoing_proposal_id =
                              e.target.value === 'ongoing' ? proposal.id ?? 'n' : null
                            setProposalData({
                              ...proposalData,
                              ongoing: e.target.value,
                              ongoing_proposal_id,
                            })
                          }}
                        />
                      ))}
                    </div>
                    <input
                      autoComplete="off"
                      type="hidden"
                      name="proposal[ongoing_proposal_id]"
                      id="proposal_ongoing_proposal_id"
                      value={
                        proposalData?.ongoing === 'ongoing'
                          ? proposalData?.id ?? 'n'
                          : proposalData?.ongoing_proposal_id
                      }
                    />
                  </>
                ) : (
                  <div>{already_starting_message}</div>
                )}
              </td>
            </tr>
            {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
              <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
                <th
                  scope="row"
                  className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
                >
                  入手日時
                </th>
                <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                  <Input
                    className="md:w-[300px]"
                    value={proposalData?.recepted_at}
                    name="proposal[sourced_at]"
                    id="proposal_sourced_at"
                    type="datetime-local"
                    onChange={(e) => {
                      const recepted_at = format(parseISO(e.target.value), 'yyyy-MM-dd HH:mm:ss')

                      setProposalData({
                        ...proposalData,
                        recepted_at,
                      })
                    }}
                    maxCalendarYear={3}
                  />
                </td>
              </tr>
            )}
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                情報入手者
                <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
                  必須
                </span>
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <div className="flex gap-4 flex-col md:flex-row">
                  <DropDown
                    className="md:w-[300px]"
                    name="proposal[source_user_id]"
                    id="proposal_source_user_id"
                    value={currentSourceUser}
                    options={usersOptions}
                    hasBlank
                    placeholder="(未設定)"
                    onChange={(e) => {
                      const source_user_id = e?.value
                      const user = current_user.company.users.find(
                        (user) => user.id === source_user_id
                      )
                      setProposalData({
                        ...proposalData,
                        source_user_id,
                        source_department_name: user?.department?.name ?? '(未設定)',
                      })
                    }}
                  />
                  <Input
                    className="md:w-[300px]"
                    value={proposalData?.source_department_name ?? '(未設定)'}
                    placeholder=""
                    name="source_department_name"
                    id="source_department_name"
                    readOnly
                    onChange={() => {
                      //
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                案件担当者
                {proposalData?.ongoing === 'ongoing' && (
                  <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
                    必須
                  </span>
                )}
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <div className="flex gap-4 flex-col md:flex-row">
                  <DropDown
                    className="md:w-[300px]"
                    name="proposal[user_id]"
                    id="proposal_user_id"
                    value={currentProposalUser}
                    options={usersOptions}
                    hasBlank
                    disabled={proposalData?.ongoing !== 'ongoing'}
                    placeholder="(未設定)"
                    onChange={(e) => {
                      const user_id = e?.value
                      const user = current_user.company.users.find((user) => user.id === user_id)
                      setProposalData({
                        ...proposalData,
                        user_id,
                        department_name:
                          proposalData?.ongoing === 'ongoing'
                            ? user?.department?.name ?? '(未設定)'
                            : '(未設定)',
                      })
                    }}
                  />
                  <Input
                    className="md:w-[300px]"
                    value={
                      proposalData?.ongoing === 'ongoing' ? proposalData?.department_name ?? '' : ''
                    }
                    placeholder=""
                    disabled={proposalData?.ongoing !== 'ongoing'}
                    name="department_name"
                    id="department_name"
                    readOnly
                    onChange={() => {
                      //
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                案件名
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  value={proposalData?.ongoing === 'ongoing' ? proposalData?.name ?? '' : ''}
                  name="proposal[name]"
                  id="proposal_name"
                  placeholder=""
                  disabled={proposalData?.ongoing !== 'ongoing'}
                  onChange={(e) => setProposalData({ ...proposalData, name: e.target.value })}
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                入手先企業
                {is_source_required && (
                  <span className="ml-2 inline-block border border-red py-0.5 px-1 text-red leading-none text-[10px]">
                    必須
                  </span>
                )}
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  value={proposalData?.source ?? ''}
                  name="proposal[source]"
                  id="proposal_source"
                  onChange={(e) => setProposalData({ ...proposalData, source: e.target.value })}
                  list="sources-list"
                  datalist={sources_list}
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                入手先支店/部署
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  value={proposalData?.source_branch ?? ''}
                  name="proposal[source_branch]"
                  id="proposal_source_branch"
                  onChange={(e) =>
                    setProposalData({ ...proposalData, source_branch: e.target.value })
                  }
                  list="sources-branch-list"
                  datalist={sources_branch_list}
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                入手先担当者
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  value={proposalData?.source_staff ?? ''}
                  name="proposal[source_staff]"
                  id="proposal_source_staff"
                  onChange={(e) =>
                    setProposalData({ ...proposalData, source_staff: e.target.value })
                  }
                  list="sources-staff-list"
                  datalist={sources_staff_list}
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                売主
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  value={proposalData?.seller ?? ''}
                  name="proposal[seller]"
                  id="proposal_seller"
                  onChange={(e) => setProposalData({ ...proposalData, seller: e.target.value })}
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                売主希望総額
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  value={proposalData?.suggested_total_price ?? 0}
                  suffix="円"
                  onChange={(e) => {
                    setProposalData({
                      ...proposalData,
                      suggested_total_price: e.target.value,
                    })
                  }}
                  onBlur={(e) => {
                    const suggested_total_price = e.target.value
                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                      : ''
                    const suggestedUnitPriceChangedData = suggestedUnitPriceChanged(proposalData)
                    setProposalData({
                      ...proposalData,
                      ...suggestedUnitPriceChangedData,
                      suggested_total_price: suggested_total_price,
                    })
                  }}
                />
                <input
                  type="hidden"
                  value={
                    proposalData?.suggested_total_price
                      ? intFormat(String(proposalData?.suggested_total_price))
                      : ''
                  }
                  name="proposal[suggested_total_price]"
                  id="proposal_suggested_total_price"
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                売主希望坪単価
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  value={proposalData?.suggested_unit_price ?? 0}
                  name="suggested_unit_price"
                  id="suggested_unit_price"
                  suffix="円"
                  readOnly
                  onChange={() => {
                    //
                  }}
                />
              </td>
            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                目安価格
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Input
                  className="md:w-[300px]"
                  value={proposalData?.approx_price ?? 0}
                  suffix="円"
                  onChange={(e) => {
                    setProposalData({
                      ...proposalData,
                      approx_price: e.target.value,
                    })
                  }}
                  onBlur={(e) => {
                    const approx_price = e.target.value
                      ? cammedFormat(dataPrecisionSetup(e.target.value, 12, 0), 0)
                      : ''
                    setProposalData({
                      ...proposalData,
                      approx_price: approx_price,
                    })
                  }}
                />
                <input
                  type="hidden"
                  value={
                    proposalData?.approx_price
                      ? intFormat(String(proposalData?.approx_price))
                      : ''
                  }
                  name="proposal[approx_price]"
                  id="approx_price"
                />
              </td>

            </tr>
            <tr className="bg-[#F1F3F4] block md:table-row [&:nth-child(even)]:bg-white">
              <th
                scope="row"
                className="pt-4 pb-2 md:py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 block md:table-cell"
              >
                備考
              </th>
              <td className="py-2 px-4 md:w-3/4 block md:table-cell">
                <Textarea
                  className="md:w-[615px]"
                  name="proposal[note]"
                  id="proposal_note"
                  placeholder="入力してください"
                  value={proposalData?.note}
                  onChange={(e) => setProposalData({ ...proposalData, note: e.target.value })}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href={`/properties/${proposal.property.hashid}?tab=proposals`}
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">{submit}</Button>
        </div>
      </form>
    </Base>
  )
}

export default ProposalsEditPage
