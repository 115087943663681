import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Button, AnchorButton } from '@/components/Button'
import type { Tag } from '@/types/tag'
import { User } from '@/types/user'
import type { Company } from '@/types/company'
import { Introduction } from '@/components/Layout/Introduction'
import { CustomizeTable } from '@/components/Table/CustomizeTable'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'

type Props = {
  current_user: User
  business_type: Tag
  columns_selected_values: { number: string }
  columns_selected_dummy_values: { number: string }
  column_list: { value: string; text: string }[]
  authenticity_token: string
  dummy_list: { value: string; text: string }[]
  businesstypes: string[]
  company: Company
  select_box_count: number
}

const BusinessTypesLayoutPage: React.FC<Props> = ({
  current_user,
  business_type,
  columns_selected_values,
  columns_selected_dummy_values,
  column_list,
  authenticity_token,
  dummy_list,
  businesstypes,
  company,
  select_box_count,
}) => {
  const title = 'レイアウト設定'
  const fixedFirstColums = ['No', '案件名', 'タグ']
  const fixedFirstValues = ['1', 'テスト', '未設定']
  const fixedLastColums = ['進捗', '進捗管理メモ更新日']
  const fixedLastValues = ['準備中', '特になし']
  const entity_values: string[] = Array(select_box_count).fill('-')
  const dummy_entity_values: string[] = Array(select_box_count).fill('-')

  const [isLoading, setIsLoading] = React.useState(false)

  // インデックスに値を挿入
  const updateEntityValues = (sourceValues, targetEntityValues) => {
    for (const [key, value] of Object.entries(sourceValues)) {
      const index = parseInt(key, 10)
      targetEntityValues[index] = value
    }
  }

  updateEntityValues(columns_selected_values, entity_values)
  updateEntityValues(columns_selected_dummy_values, dummy_entity_values)

  const middleIndex = Math.ceil(dummy_entity_values.length / 2)

  const [isFirstDummyEntityValues, setFirstDummyEntityValues] = React.useState(
    dummy_entity_values.slice(0, middleIndex)
  )
  const [isSecondDummyEntityValues, setSecondDummyEntityValues] = React.useState(
    dummy_entity_values.slice(middleIndex)
  )

  const defult_first_select_name = entity_values.slice(0, middleIndex).map((value) => {
    // column_listから該当するテキストを検索
    const match = column_list.find((item) => item.value === value)
    // 該当する場合はテキスト、そうでない場合は元の値
    return match ? match.text : value
  })
  const defult_second_select_name = entity_values.slice(middleIndex).map((value) => {
    // column_listから該当するテキストを検索
    const match = column_list.find((item) => item.value === value)
    // 該当する場合はテキスト、そうでない場合は元の値
    return match ? match.text : value
  })

  const [isFirstSelectName, setFirstSelectName] = React.useState(defult_first_select_name)
  const [isSecondSelectName, setSecondSelectName] = React.useState(defult_second_select_name)

  const isSelectBoxOptions = column_list.map((item) => item.text)

  const introduction_message = [
    '◾️ 案件一覧のカラムの順番を変更できます。',
    '◾️ 右矢印をクリックすると右にカラムが移動し、左矢印をクリックすると左に移動します。',
    '◾️ PC幅の場合、「No.」「案件名」「タグ」「進捗」「進捗管理メモ更新日」の位置は固定です。',
    '◾️ 他事業部のカラム設定をコピーして編集できます。( ※ 保存せず、画面をリロードするとページを開いた時の状態に戻るため、ご注意してください。)',
  ]

  const onSelectBoxChange = (i, value) => {
    if (i < isFirstSelectName.length) {
      setFirstSelectName((v) => {
        const newState = [...v]
        newState[i] = value
        return newState
      })
      setFirstDummyEntityValues((v) => {
        const newState = [...v]
        const matchingItem = column_list.find((item) => item.text === value)
        const updatedDummyValue = dummy_list.find((item) => item.value === matchingItem.value)
        newState[i] = updatedDummyValue.text
        return newState
      })
    } else if (i > isSecondSelectName.length - 1) {
      setSecondSelectName((v) => {
        const newState = [...v]
        newState[i - isSecondSelectName.length] = value
        return newState
      })
      setSecondDummyEntityValues((v) => {
        const newState = [...v]
        const matchingItem = column_list.find((item) => item.text === value)
        const updatedDummyValue = dummy_list.find((item) => item.value === matchingItem.value)
        newState[i - isSecondSelectName.length] = updatedDummyValue.text
        return newState
      })
    }
  }

  const swapItems = (index: number, direction: number) => {
    setFirstSelectName((v) => {
      const newState = [...v]
      ;[newState[index], newState[index + direction]] = [
        newState[index + direction],
        newState[index],
      ]
      return newState
    })
    setFirstDummyEntityValues((v) => {
      const newState = [...v]
      ;[newState[index], newState[index + direction]] = [
        newState[index + direction],
        newState[index],
      ]
      return newState
    })
    setSecondSelectName((v) => {
      const newState = [...v]
      ;[newState[index], newState[index + direction]] = [
        newState[index + direction],
        newState[index],
      ]
      return newState
    })
    setSecondDummyEntityValues((v) => {
      const newState = [...v]
      ;[newState[index], newState[index + direction]] = [
        newState[index + direction],
        newState[index],
      ]
      return newState
    })
  }

  const moveLeft = (index: number) => {
    swapItems(index, -1)
  }
  const moveRight = (index: number) => {
    swapItems(index, 1)
  }

  const [selectedBusinesstypes, setSelectedBusinesstypes] = React.useState('未設定')

  const handleChangeSelect = (e) => {
    const value = e.target.value
    if (value !== '') {
      setSelectedBusinesstypes(value || '未設定')
      const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      setIsLoading(true)
      fetch('/business_types/business_types_list', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken, // CSRF トークンをヘッダーに追加
        },
        body: JSON.stringify({
          request_businesstype_name: value,
          company_id: company.id,
          business_type_id: business_type.id,
        }),
      })
        .then((response) => {
          if (!response.ok) throw new Error('接続に失敗しました')
          return response.json()
        })
        .then((data) => {
          console.log('Success:', data)
          const emptyObject = {}
          data.copy_businesstype.forEach((item) => {
            emptyObject[item.order] = item.column_name
          })
          const emptySelectBox: string[] = Array(select_box_count).fill('-')
          updateEntityValues(emptyObject, emptySelectBox)
          const copyFirstSelectName = emptySelectBox.slice(0, middleIndex).map((value) => {
            const match = column_list.find((item) => item.value === value)
            return match ? match.text : value
          })
          const copySecondSelectName = emptySelectBox.slice(middleIndex).map((value) => {
            const match = column_list.find((item) => item.value === value)
            return match ? match.text : value
          })
          setFirstSelectName(copyFirstSelectName)
          setSecondSelectName(copySecondSelectName)
          const copyDummyValue = emptySelectBox.map((value) => {
            if (value === '-') {
              return '-'
            }
            const updatedDummyValue = dummy_list.find((item) => item.value === value)
            return updatedDummyValue ? updatedDummyValue.text : value
          })
          setFirstDummyEntityValues(copyDummyValue.slice(0, middleIndex))
          setSecondDummyEntityValues(copyDummyValue.slice(middleIndex))
        })
        .catch((error) => {
          console.error('Error:', error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      console.log('未設定')
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const send_first_select_name = isFirstSelectName.map((value) => {
      const match = column_list.find((item) => item.text === value)
      return match ? match.value : value
    })
    const send_second_select_name = isSecondSelectName.map((value) => {
      const match = column_list.find((item) => item.text === value)
      return match ? match.value : value
    })

    const sendToPayload = {
      column_values: [...send_first_select_name, ...send_second_select_name], // セレクトボックスの選択値
    }

    fetch(`/business_types/${business_type.id}/upsert_layout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticity_token,
      },
      body: JSON.stringify(sendToPayload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('通信に失敗しました')
        }
        return response.json()
      })
      .then((data) => {
        console.log('サーバーの応答:', data)
      })
      .catch((error) => {
        console.error('エラー:', error)
      })
      .finally(() => {
        window.location.href = '/business_types'
        setTimeout(() => setIsLoading(false), 2 * 60 * 1000)
      })
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['事業形態設定', 'レイアウト設定']} />}
      submenuCategory="settings"
    >
      <form
        className="mt-8"
        onSubmit={handleSubmit}
        action={`/business_types/${business_type.id}/upsert_layout`}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value="post" />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        <div className="w-full h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <div>事業形態：{business_type.name}</div>
          <Introduction introduction_message={introduction_message} details_style={'my-4'} />
          <div className="mb-2">
            <select
              id="department-select"
              className={[
                `mb-2 empty:hidden inline-flex text-sm ml-2 px-3 py-[4px] rounded-full border-[1px] border-[#EEE] border-transparent text-black`,
              ].join(' ')}
              value={selectedBusinesstypes}
              onChange={handleChangeSelect}
            >
              <option value="">事業形態のコピー</option>
              {businesstypes
                .filter((businesstype) => businesstype !== business_type.name)
                .map((businesstype, index) => (
                  <option key={index} value={businesstype}>
                    {businesstype}
                  </option>
                ))}
            </select>
            <p>選択した部署: {selectedBusinesstypes}</p>
          </div>
          <LoadingOverlay isOpen={isLoading} text="反映中" />
          <div className="flex flex-row gap-1 w-full overflow-scroll pt-[15px] pb-[20px]">
            <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center h-[119px]">
              No.
            </div>
            <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center flex-none h-[119px]">
              案件名
            </div>
            <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center flex-none h-[119px]">
              タグ
            </div>
            {/* <div>
              <CustomizeTable
                use_view_name="bussiness_type_layout"
                display_keys={fixedFirstColums}
                display_values={fixedFirstValues}
                display_visibility_num={fixedLastColums.length}
                wrapper={'flex flex-wrap justify-between items-start'}
                td_height={'h-[141px]'}
                height={'h-[110px]'}
                thead_color={'bg-[#c3deee]'}
              />
            </div> */}
            <div>
              <CustomizeTable
                use_view_name="bussiness_type_layout"
                first_select_name={isFirstSelectName}
                second_select_name={isSecondSelectName}
                display_keys={isSelectBoxOptions}
                first_display_values={isFirstDummyEntityValues}
                socond_display_values={isSecondDummyEntityValues}
                purpose="dubble_table"
                top_scrollbar={false}
                onSelectBoxChange={onSelectBoxChange}
                moveLeft={moveLeft}
                moveRight={moveRight}
                container="max-h-[calc(100vh-200px)] w-full"
              />
            </div>
            {/* <div>
              <CustomizeTable
                use_view_name="bussiness_type_layout"
                display_keys={fixedLastColums}
                display_values={fixedLastValues}
                wrapper={'flex flex-wrap justify-between items-start'}
                td_height={'h-[141px]'}
                height={'h-[110px]'}
              />
            </div> */}
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center flex-none h-[119px]">
                進捗
              </div>
              <div className="flex border-solid border-[1px] border-gray-border rounded-sm px-3 items-center justify-center flex-none h-[119px]">
                進捗メモ更新日
              </div>
          </div>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href="/business_types"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto" type="submit">
            編集を保存
          </Button>
        </div>
      </form>
    </Base>
  )
}

export default BusinessTypesLayoutPage
