import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import ClearIcon from '@material-ui/icons/Clear'



type Props = {
  isOpen: boolean;
  buriedCulturalPropertyLinks?: any;
  property:any;
}


export const BuriedCulturaPropertyModal: React.FC<Props> = ({
    isOpen = false,
    buriedCulturalPropertyLinks,
    property,
}) => {
  const [isAlertOpen, setAlertOpen] = React.useState(false)
  const title = "埋蔵文化財包蔵地"
  const closeAlertModal = () => {
    setAlertOpen(false);
  };

  const openAlertModal = () => {
    setAlertOpen(true);
  };

  // 以下のコメントアウトはDebugように残しておいてください
  console.log(property?.prefecture,property?.city)


  return (
    <>
      <div
        style={{ display: isOpen ? 'inline-flex' : 'none' }}
        className={`z-10 text-[#777] inline-flex px-6 md:px-8 py-[8px] mt-[1px] bg-[#f4f5f6] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)] select-none hover:bg-targetLine cursor-pointer`}
        onClick={openAlertModal}
      >
        {title}
      </div>

      <Transition appear show={isAlertOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeAlertModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                    {title}
                    <ClearIcon className="float-right cursor-pointer" onClick={closeAlertModal} />
                  </Dialog.Title>
                  <hr />
                  <p className='p-[1.5rem] text-[0.85rem]'>
                    外部リンク先の情報は、あくまで参考情報となります。<br/>
                    埋蔵文化財のお問い合わせについては、必ず各市区町村の窓口へ照会をお願いいたします。
                  </p>
                  <div className="text-center m-4">
                    <a
                      href={`${buriedCulturalPropertyLinks(property?.prefecture, property?.city)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                        <Button
                        className="text-white w-[120px]"
                        size="small"
                        variant="primary"
                        >
                        確認しました
                        </Button>
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default BuriedCulturaPropertyModal
