import * as React from 'react'
import { format, parseISO } from 'date-fns'

import { Table } from '@/components/Table'
import type { Property } from '@/types/property'
import type { User } from '@/types/user'

import { PlusIcon, SearchIcon2, CloseIcon2, EditIcon2 } from '@/components/SvgIcon'
import { AnchorButton } from '@/components/Button'
import { colors } from '@/utils/colors'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'

import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'

import { formatNumber } from '@/utils/cammedFormat'
import Linkify from 'react-linkify'
import { componentDecorator } from './linkComponentDecorator'

const PropetyProposals: React.FC<{
  user: User
  property: Property
  source_display: { [key: number]: boolean }
}> = ({ property, source_display, user }) => {
  return (
    <div className="mt-10 mb-10">
      <div className="border-b-[1px] border-primary mb-2">
        <span className="inline-flex bg-primary text-white text-sm py-2 px-8">入手情報</span>
      </div>
      <div className="text-right mb-2">
        <AnchorButton
          className="text-primary-font"
          prefix={<AddOutlinedIcon fontSize="small" />}
          outline
          size="small"
          variant="primary"
          href={`/proposals/new?tab=proposals&property_id=${property.hashid}`}
        >
          <span className="hidden md:inline">入手情報追加</span>
        </AnchorButton>
      </div>

      {property.proposals
        .sort((a, b) => (a.recepted_at > b.recepted_at ? 1 : -1))
        .map((proposal, index) => {
          const edit_condition_with_ongoing_proposal =
            property.ongoing_proposal_id == proposal.id &&
            ((user.role === 'leader' && property.user?.department_id == user.department_id) ||
              (user.role === 'general' && property.user?.id == user.id))

          const edit_condition_without_ongoing_proposal =
            property.ongoing_proposal_id != proposal.id &&
            ((user.role === 'leader' && proposal.source_user?.department_id == user.department_id) ||
              (user.role === 'general' && proposal.source_user?.id == user.id))

          const show_detail_button = property.company_id == user.company_id

          const show_edit_button =
            property.company_id == user.company_id &&
            ((user.role !== 'leader' && user.role !== 'general') ||
              edit_condition_with_ongoing_proposal ||
              edit_condition_without_ongoing_proposal)

          const show_destroy_button =
            property.company_id == user.company_id &&
            user.role !== 'leader' &&
            user.role !== 'general'

          const notes = proposal.note?.split('\n') || []

          return (
            <div key={index} className={[index > 0 && 'mt-10'].join(' ')}>
              <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
                <span>
                  <span className="pr-4">
                    No.{index + 1} {format(parseISO(proposal.recepted_at), 'yyyy/MM/dd HH:mm:ss')}{' '}
                  </span>
                  {property.ongoing_proposal_id == proposal.id && (
                    <span className="flex md:inline-block text-sm font-bold px-4 py-[4px] rounded-xl border-[2px] border-targetLine bg-[#3478a0] text-targetLine shadow-md w-[80px]">
                      検討中
                    </span>
                  )}
                </span>
                <div className="flex gap-2 items-center">
                  {show_detail_button && (
                    <a
                      href={`/proposals/${proposal.hashid}?tab=proposals&property_id=${property.hashid}`}
                      className="inline-flex items-center text-sm px-1 md:px-2 py-1 rounded bg-[#EDF8FF] text-primary-font"
                    >
                      <SearchIcon2 size={15} fill="#415D6C" className="md:mr-1" />
                      <span className="hidden md:inline">詳細</span>
                    </a>
                  )}
                  {show_edit_button && (
                    <a
                      href={`/proposals/${proposal.hashid}/edit?tab=proposals&property_id=${property.hashid}`}
                      className="inline-flex items-center text-sm px-1 md:px-2 py-1 rounded bg-[#EDF8FF] text-primary-font"
                    >
                      <EditIcon2 size={15} fill="#415D6C" className="md:mr-1" />
                      <span className="hidden md:inline">編集</span>
                    </a>
                  )}
                  {show_destroy_button && (
                    <a
                      data-method="delete"
                      data-confirm="削除しますか？"
                      href={`/proposals/${proposal.hashid}?redirect_to=%2Fproperties%2F${property.hashid}%3Ftab%3Dproposals`}
                      className="inline-flex items-center text-sm px-1 md:px-2 py-1 rounded bg-[#EDF8FF] text-deleteHover"
                    >
                      <CloseIcon2 size={15} fill="#D21919" className="md:mr-1" />
                      <span className="hidden md:inline">削除</span>
                    </a>
                  )}
                </div>
              </div>
              <Table className="border-t border-b border-[#3885B0]" tag="div">
                <div>
                  <div className="flex flex-wrap">
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          情報入手者
                        </Th>
                      }
                    >
                      <Td column={2}>
                        {proposal.source_user?.name}
                        {proposal.source_user?.department &&
                          `/ ${proposal.source_user.department.name}`}
                      </Td>
                    </Row>
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          入手先
                        </Th>
                      }
                    >
                      <Td column={2}>{source_display[proposal.id]}</Td>
                    </Row>
                  </div>
                  <div className="flex flex-wrap">
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          売主希望総額
                        </Th>
                      }
                    >
                      <Td column={2}>
                        {proposal.suggested_total_price
                          ? `${formatNumber(proposal.suggested_total_price)}円`
                          : ''}
                      </Td>
                    </Row>
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          売主希望坪単価
                        </Th>
                      }
                    >
                      <Td column={2}>
                        {proposal.suggested_unit_price
                          ? `${formatNumber(proposal.suggested_unit_price)}円`
                          : ''}
                      </Td>
                    </Row>
                  </div>
                  <div className="flex flex-wrap">
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          目安価格
                        </Th>
                      }
                    >
                      <Td multiLine={true} column={2}>
                        {proposal.approx_price
                            ? `${formatNumber(proposal.approx_price)}円`
                            : ''}
                      </Td>
                    </Row>
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          売主
                        </Th>
                      }
                    >
                      <Td column={2}>{proposal.seller}</Td>
                    </Row>
                  </div>
                  <div className="flex flex-wrap">
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          商談方式
                        </Th>
                      }
                    >
                      <Td column={2}>{proposal.negotiation_method}</Td>
                    </Row>
                    <Row
                      className="w-full md:w-1/2"
                      label={
                        <Th left={true} column={2}>
                          備考
                        </Th>
                      }
                    >
                      <Td multiLine={true} column={2}>
                        {notes.map((note) => (
                          <div className="break-all">
                            <Linkify componentDecorator={componentDecorator}>
                              {note.trim() === '' ? '　' : note}
                            </Linkify>
                          </div>
                        ))}
                      </Td>
                    </Row>
                  </div>
                </div>
              </Table>
            </div>
          )
        })}
    </div>
  )
}

export default PropetyProposals
