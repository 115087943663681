import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Button, AnchorButton } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { User } from '@/types/user'
import { DisplayItem, TabDisplayItems } from '@/types/displayItem'
import { RequiredItem } from '@/types/requiredItem'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Table } from '@/components/Table'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import Tooltip from '@mui/material/Tooltip'
import type { Property } from '@/types/property'
import { cammedFormat, formatNumber } from '@/utils/cammedFormat'
import { buildingStructureType } from '@/config/langs/building_structure'
import { format, formatDistanceStrict, parseISO } from 'date-fns'
import Linkify from 'react-linkify'
import { ja } from 'date-fns/locale'
import { componentDecorator } from './linkComponentDecorator'

type Props = {
  current_user?: User
  property?: Property
  display_item_data: DisplayItem[]
  tab_display_items?: TabDisplayItems
  source_display?: { [key: number]: boolean }
  required_item_data?: RequiredItem[]
  authenticity_token?: string
  error_messages?: string[]
}
export const PropertyGetInfomation: React.FC<Props> = ({
  property,
  current_user,
  display_item_data,
  tab_display_items = {},
  source_display,
  required_item_data,
  authenticity_token,
  error_messages,
}) => {
  const is_get_infomaion_item_names = display_item_data
    .filter((item) => tab_display_items[item.display_item_id] === false)
    .map((item) => item.name)
  let ongoing_proposal_column_width = 'w-full'
  let ongoing_proposal_width = 'w-full'
  let negotiation_method_width = 'w-full'
  let negotiation_method_column_width = 'w-full'
  let seller_width = 'w-full'
  let seller_column_width = 'w-full'
  let purchace_applied_at_width = 'w-full'
  let purchace_applied_at_column_width = 'w-full'
  let purchace_contract_at_width = 'w-full'
  let purchace_contract_at_column_width = 'w-full'
  let purchace_settlement_at_width = 'w-full'
  let purchace_settlement_at_column_width = 'w-full'

  if (
    is_get_infomaion_item_names.includes('商談方式') &&
    is_get_infomaion_item_names.includes('売主')
  ) {
    ongoing_proposal_width += ' md:w-full'
    ongoing_proposal_column_width += ' md:w-2/12'
  } else if (
    is_get_infomaion_item_names.includes('商談方式') ||
    is_get_infomaion_item_names.includes('売主')
  ) {
    ongoing_proposal_width += ' md:w-1/2'
    ongoing_proposal_column_width += ' md:w-1/3'
  } else {
    ongoing_proposal_width += ' md:w-1/3'
  }
  if (
    is_get_infomaion_item_names.includes('入手先') &&
    is_get_infomaion_item_names.includes('売主')
  ) {
    negotiation_method_width += ' md:w-full'
    negotiation_method_column_width += ' md:w-2/12'
  } else if (
    is_get_infomaion_item_names.includes('入手先') ||
    is_get_infomaion_item_names.includes('売主')
  ) {
    negotiation_method_width += ' md:w-1/2'
    negotiation_method_column_width += ' md:w-1/3'
  } else {
    negotiation_method_width += ' md:w-1/3'
  }
  if (
    is_get_infomaion_item_names.includes('入手先') &&
    is_get_infomaion_item_names.includes('商談方式')
  ) {
    seller_width += ' md:w-full'
    seller_column_width += ' md:w-2/12'
  } else if (
    is_get_infomaion_item_names.includes('入手先') ||
    is_get_infomaion_item_names.includes('商談方式')
  ) {
    seller_width += ' md:w-1/2'
    seller_column_width += ' md:w-1/3'
  } else {
    seller_width += ' md:w-1/3'
  }

  if (
    is_get_infomaion_item_names.includes('仕入契約日') &&
    is_get_infomaion_item_names.includes('仕入決済日')
  ) {
    purchace_applied_at_width += ' md:w-full'
    purchace_applied_at_column_width += ' md:w-2/12'
  } else if (
    is_get_infomaion_item_names.includes('仕入契約日') ||
    is_get_infomaion_item_names.includes('仕入決済日')
  ) {
    purchace_applied_at_width += ' md:w-1/2'
    purchace_applied_at_column_width += ' md:w-1/3'
  } else {
    purchace_applied_at_width += ' md:w-1/3'
  }
  if (
    is_get_infomaion_item_names.includes('買付提出日') &&
    is_get_infomaion_item_names.includes('仕入決済日')
  ) {
    purchace_contract_at_width += ' md:w-full'
    purchace_contract_at_column_width += ' md:w-2/12'
  } else if (
    is_get_infomaion_item_names.includes('買付提出日') ||
    is_get_infomaion_item_names.includes('仕入決済日')
  ) {
    purchace_contract_at_width += ' md:w-1/2'
    purchace_contract_at_column_width += ' md:w-1/3'
  } else {
    purchace_contract_at_width += ' md:w-1/3'
  }
  if (
    is_get_infomaion_item_names.includes('買付提出日') &&
    is_get_infomaion_item_names.includes('仕入契約日')
  ) {
    purchace_settlement_at_width += ' md:w-full'
    purchace_settlement_at_column_width += ' md:w-2/12'
  } else if (
    is_get_infomaion_item_names.includes('買付提出日') ||
    is_get_infomaion_item_names.includes('仕入契約日')
  ) {
    purchace_settlement_at_width += ' md:w-1/2'
    purchace_settlement_at_column_width += ' md:w-1/3'
  } else {
    purchace_settlement_at_width += ' md:w-1/3'
  }
  const [isViewUseDisplayItemsFlg, setIsViewUseDisplayItemsFlg] = React.useState(false)

  React.useEffect(() => {
    const url = window.location.href

    if (url.includes('display_items')) {
      setIsViewUseDisplayItemsFlg(true)
    } else {
      setIsViewUseDisplayItemsFlg(false)
    }
  }, [])
  return (
    <>
      <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
        入手情報
      </div>
      <Table className="border-t border-b border-[#3885B0]">
        <div className={`${isViewUseDisplayItemsFlg ? 'text-xs' : ''}`}>
          <div className="flex flex-wrap">
            <Row
              className={`
                    ${ongoing_proposal_width}
                    ${is_get_infomaion_item_names.includes('入手先') ? 'hidden' : ''}
                  `}
              label={
                <Th left={true} column={3} className={ongoing_proposal_column_width}>
                  入手先
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '入手先企業／例入手先支店／例入手先担当者'
                  : property?.ongoing_proposal
                  ? source_display[property?.ongoing_proposal.id]
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                      ${negotiation_method_width}
                      ${is_get_infomaion_item_names.includes('商談方式') ? 'hidden' : ''}
                    `}
              label={
                <Th left={true} column={3} className={negotiation_method_column_width}>
                  商談方式
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '相対'
                  : property?.ongoing_proposal?.negotiation_method || ''}
              </Td>
            </Row>
            <Row
              className={`
                      ${seller_width}
                      ${is_get_infomaion_item_names.includes('売主') ? 'hidden' : ''}
                    `}
              label={
                <Th left={true} column={3} className={seller_column_width}>
                  売主
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '売主'
                  : property?.ongoing_proposal?.seller}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                    ${
                      is_get_infomaion_item_names.includes('経過日数')
                        ? 'w-full'
                        : 'w-full md:w-1/2'
                    }
                    ${is_get_infomaion_item_names.includes('入手日') ? 'hidden' : ''}
                  `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_get_infomaion_item_names.includes('経過日数') ? 'md:w-2/12' : ''
                  }`}
                >
                  入手日
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '2024/01/01'
                  : property?.ongoing_proposal?.sourced_at
                  ? format(parseISO(property.ongoing_proposal.sourced_at), 'yyyy/MM/dd')
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                    ${is_get_infomaion_item_names.includes('入手日') ? 'w-full' : 'w-full md:w-1/2'}
                    ${is_get_infomaion_item_names.includes('経過日数') ? 'hidden' : ''}
                  `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${is_get_infomaion_item_names.includes('入手日') ? 'md:w-2/12' : ''}`}
                >
                  経過日数
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '0日'
                  : property?.ongoing_proposal &&
                    `${formatDistanceStrict(
                      new Date(),
                      new Date(property?.ongoing_proposal?.sourced_at),
                      {
                        locale: ja,
                        unit: 'day',
                      }
                    )}`}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                    ${purchace_applied_at_width}
                    ${is_get_infomaion_item_names.includes('買付提出日') ? 'hidden' : ''}
                  `}
              label={
                <Th left={true} column={3} className={purchace_applied_at_column_width}>
                  買付提出日
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '2024/01/01'
                  : property?.purchace_applied_at &&
                    format(parseISO(property?.purchace_applied_at), 'yyyy/MM/dd')}
              </Td>
            </Row>
            <Row
              className={`
                    ${purchace_contract_at_width}
                    ${is_get_infomaion_item_names.includes('仕入契約日') ? 'hidden' : ''}
                  `}
              label={
                <Th left={true} column={3} className={purchace_contract_at_column_width}>
                  仕入契約日
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '2024/01/01'
                  : property?.purchace_contract_at &&
                    format(parseISO(property?.purchace_contract_at), 'yyyy/MM/dd')}
              </Td>
            </Row>
            <Row
              className={`
                    ${purchace_settlement_at_width}
                    ${is_get_infomaion_item_names.includes('仕入決済日') ? 'hidden' : ''}
                  `}
              label={
                <Th left={true} column={3} className={purchace_settlement_at_column_width}>
                  仕入決済日
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '2024/01/01'
                  : property?.purchace_settlement_at &&
                    format(parseISO(property?.purchace_settlement_at), 'yyyy/MM/dd')}
              </Td>
            </Row>
          </div>
          <Row
            className={`${is_get_infomaion_item_names.includes('備考') ? 'hidden' : ''}`}
            label={<Th left={true}>備考</Th>}
          >
            <Td>
              <p className="whitespace-pre">
                <Linkify componentDecorator={componentDecorator}>
                  {window.location.href.includes('display_items')
                    ? '備考'
                    : property?.ongoing_proposal?.note}
                </Linkify>
              </p>
            </Td>
          </Row>
        </div>
      </Table>
    </>
  )
}
