import { MarketDatum } from '@/types/marketDatum'
import { formatColumn } from '@/utils/marketDatumFormatColumn'
import OpenInNew from '@mui/icons-material/OpenInNew';
import * as React from 'react'
import * as ReactDOMServer from 'react-dom/server'

export function Balloon(marketData: MarketDatum) {
  const forSale =
    (new URL(window.location.href).searchParams.get('data_type') || 'for_sale') == 'for_sale'
  const isClosed = marketData?.status === 'close'
  const tabIndex = forSale ? 0 : 1;
  const getPropertyParameter = window.location.href.match(/\/properties\/([^\/?,]+)/);
  const propertyHashId = getPropertyParameter ? getPropertyParameter[1] : null;
  const detailPath = (marketDataHashId, propertyHashId, tabIndex) => {
    return `/market_data/${marketDataHashId}/?property_id=${propertyHashId}&tab_index=${tabIndex}`
  }
  const jsx = (
    <div className="flex justify-center w-auto">
      <div
        className="inline-block bg-white border-2 rounded min-w-42 gap-0 w-full"
        style={{ borderColor: marketData?.status === 'open' ? '#3f51b5' : '#ff9800' }}
      >
        <p className="m-1 p-0 text-sm inline font-bold">
          {marketData?.selectNo || marketData?.no}{marketData?.status_text}
          <a
            id="market_datum_report_detail_transition" 
            className="float-right"
            href={`${detailPath(marketData?.hashid,propertyHashId, tabIndex)}`}
            target="_blank"
          >
            <OpenInNew style={{ width: '17px', height: '18px' }} />
          </a>
        </p>
        <p style={{ background: 'gray', padding: 0, margin: 0, width: '', height: '0.1px' }} />
        {forSale ? (
          <>
            <span className="p-0 m-0 mx-1 font-bold text-sm inline">
              {formatColumn(marketData, isClosed ? 'building_price_4' : 'sale_price_4')}{' '}
              {formatColumn(marketData, 'building_structure_short')}
            </span>
            <br />
            <span className="p-0 m-0 mx-1 font-bold text-sm inline">
              {formatColumn(marketData, isClosed ? 'prospective_yield' : 'sale_prospective_yield')}{' '}
              {formatColumn(marketData, 'build_age')}
            </span>
          </>
        ) : (
          <>
            <span className="p-0 m-0 mx-1 font-bold text-sm inline">
              {formatColumn(marketData, 'rent_maintenance_fee')} @
              {formatColumn(marketData, 'rent_maintenance_fee_unit_price_tsubo')}
            </span>
            <br />
            <span className="p-0 m-0 mx-1 font-bold text-sm inline">
              {formatColumn(marketData, 'occupied_area')} {formatColumn(marketData, 'build_age')}{' '}
              {formatColumn(marketData, 'building_structure_short')}
            </span>
          </>
        )}
      </div>
    </div>
  )
  return jsxToHtml(jsx)
}

function jsxToHtml(jsx: React.ReactElement): string {
  return ReactDOMServer.renderToStaticMarkup(jsx)
}
