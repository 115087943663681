import * as React from 'react'

interface MultiSelectCheckboxModalProps {
  purpose?: string
  isMultiSelectCheckboxModalFlg: boolean
  toggleMultiSelectCheckboxModalFlg: () => void
  height?: string
  background?: string
  border?: string
  border_radius?: string
  box_shadow?: string
  z_index?: string
  opacity?: string
  transform?: string
  transition?: string
  position?: string
  item_name?: string[]
  item_value?: boolean[]
  setItemValue?: any
  inversion_value?: boolean
  disabled_index?: number
  base_style?: string
  first_elm?: boolean
  second_elm?: boolean
  first_elm_base?: string
  second_elm_base?: string
}

export const MultiSelectCheckboxModal: React.FC<MultiSelectCheckboxModalProps> = ({
  purpose = 'manual',
  isMultiSelectCheckboxModalFlg,
  toggleMultiSelectCheckboxModalFlg,
  position = 'absolute',
  height = '70%',
  background = 'white',
  border = '1px solid #0b689d',
  border_radius = '10px',
  box_shadow = '0 4px 6px rgba(0, 0, 0, 0.11)',
  z_index = '999',
  opacity = '0',
  transform = 'scale(0)',
  transition = 'opacity 1s ease, transform 1s ease',
  item_name = [],
  item_value = [],
  inversion_value = false,
  setItemValue,
  disabled_index = 3,
  base_style = `multi-select-checkbox w-[85%] md:w-[50%]`,
  // ３つ以降のelm必要な場合は以下を参考に作ってください
  first_elm = false,
  first_elm_base = `text-center w-[30%]`,
  second_elm = false,
  second_elm_base = `text-center w-[70%]`,
}) => {
  const [isVisible, setIsVisible] = React.useState(isMultiSelectCheckboxModalFlg)
  const [fadeClass, setFadeClass] = React.useState('fade-out')
  const multiSelectRef = React.useRef<HTMLDivElement>(null)
  item_value = inversion_value ? item_value.map((value) => !value) : item_value

  React.useEffect(() => {
    if (isMultiSelectCheckboxModalFlg) {
      setIsVisible(true)
      const timer = setTimeout(() => setFadeClass('fade-in'), 10)
      return () => clearTimeout(timer)
    } else {
      setFadeClass('fade-out')
      const timer = setTimeout(() => setIsVisible(false), 380)
      return () => clearTimeout(timer)
    }
  }, [isMultiSelectCheckboxModalFlg])

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (multiSelectRef.current && !multiSelectRef.current.contains(event.target as Node)) {
        toggleMultiSelectCheckboxModalFlg()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [toggleMultiSelectCheckboxModalFlg])

  const toggleValueAtIndex = (index: number) => (prev: any) =>
    prev.map((value: boolean, i: number) =>
      i === index && index >= disabled_index ? !value : value
    )

  const handleCheckboxChange = (index: number) => {
    if (index < disabled_index) {
      console.log(`Checkbox ${index + 1} is read-only.`)
      return
    }
    // 固定数のチェックボックスは変更しない
    if (index < disabled_index) return
    // 選択状態をトグル
    setItemValue(toggleValueAtIndex(index))
  }

  const allHandleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = inversion_value ? !e.target.checked : e.target.checked
    const updatedItemValue = item_value.map(() => isChecked)
    inversion_value
      ? updatedItemValue.fill(false, 0, disabled_index)
      : updatedItemValue.fill(true, 0, disabled_index)
    setItemValue(updatedItemValue)
  }
  const all_change_value = item_value.every((element) => element === true)
  if (purpose === 'manual') {
    return (
      <>
        <style>{`
          .multi-select-checkbox {
            position: ${position};
            min-height: ${height};
            background: ${background};
            border: ${border};
            border-radius: ${border_radius};
            box-shadow: ${box_shadow};
            z-index: ${z_index};
            opacity: ${opacity};
            transform: ${transform};
            transition: ${transition};
          }
          .fade-in {
            opacity: 1;
            transform: scale(1);
          }
          .fade-out {
            opacity: ${opacity};
            transform: ${transform};
          }
        `}</style>
        {isVisible && (
          <div className="flex w-full justify-center">
            <div ref={multiSelectRef} className={`${base_style} ${fadeClass}`}>
              <div
                className="flex w-full justify-center border-b border-[#014397] rounded-t-lg cursor-pointer  bg-[#0b689d] hover:opacity-80 text-white"
                onClick={toggleMultiSelectCheckboxModalFlg}
              >
                閉じる
              </div>
              <div className="flex w-full justify-center overflow-y-auto h-[70vh]">
                <div className="flex w-full">
                  {first_elm && (
                    <div className={`${first_elm_base}`}>
                      <input
                        type="checkbox"
                        className="cursor-pointer"
                        checked={all_change_value}
                        onChange={(e) => allHandleCheckboxChange(e)}
                      />
                      {item_value.map((value, index) => (
                        <div key={index} className="flex items-center justify-center h-[2rem]">
                          <input
                            type="checkbox"
                            className="cursor-pointer"
                            checked={value}
                            onChange={() => handleCheckboxChange(index)}
                            disabled={index < disabled_index}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {second_elm && (
                    <div className={`${second_elm_base}`}>
                      <p className={`text-left`}>
                        {all_change_value ? '全項目の選択を解除' : '全項目を選択'}
                      </p>
                      {item_name.map((name, index) => (
                        <div key={index} className="flex items-center h-[2rem]">
                          <p>{name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}
