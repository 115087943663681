export function setup3Dbutton(
  map: google.maps.Map,
  controlPosition: google.maps.ControlPosition = google.maps.ControlPosition.LEFT_BOTTOM
) {
  // 3D表示ボタンを追加
  let is3D = false
  const map3DButton = document.createElement('button')
  map3DButton.textContent = '3D'

  map3DButton.classList.add('custom-map-control-button')
  map3DButton.setAttribute('type', 'button')
  map3DButton.style.fontSize = '14px'
  map3DButton.style.display = map.getMapTypeId() === 'styled_map' ? 'none' : 'block'

  map3DButton.addEventListener('click', () => {
    is3D = !is3D
    map.setTilt(is3D ? 45 : 0)
    map3DButton.textContent = is3D ? '2D' : '3D'
  })

  // 地図、航空写真ボタンを追加
  const mapTypeButton = document.createElement('button')
  mapTypeButton.textContent = map.getMapTypeId() === 'styled_map' ? '航空写真' : '地図'

  mapTypeButton.classList.add('custom-map-control-button')
  mapTypeButton.setAttribute('type', 'button')
  mapTypeButton.style.fontSize = '14px'

  const mapBox = document.createElement('div')
  mapBox.style.display = 'flex'
  mapBox.appendChild(mapTypeButton)
  mapBox.appendChild(map3DButton)

  map.controls[controlPosition].push(mapBox)

  mapTypeButton.addEventListener('click', () => {
    map.setMapTypeId(map.getMapTypeId() === 'styled_map' ? 'satellite' : 'styled_map')
    const styledMap = map.getMapTypeId() === 'styled_map'
    mapTypeButton.textContent = styledMap ? '航空写真' : '地図'
    map3DButton.style.display = styledMap ? 'none' : 'block'
  })
}
